@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/mixins/mediaqueries';

.tmx-ExpertList {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  min-height: 1000px;
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  color: $granite-grey;

  @include tablet-view {
    width: 100vw;
    padding: 80px 40px 30px;
  }

  @include extra-small-mobile {
    width: 100vw;
    padding: 100px 10px;
  }

  &__backButton {
    transform: rotate(180deg) !important;
    color: $spun-pearl;
    padding-top: 0 !important;
  }

  &__backContent {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: 500;
    margin-left: -28px;
    // margin-top: -5px;
    width: 100%;

    .icon {
      transform: rotate(90deg);
      padding-right: 0;
      padding-top: 8px;
    }

    @include desktop {
      margin-left: -74px;
    }
  }

  &__container {
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    background: $white;
    padding: 20px 80px 60px;
    margin: 0 auto;
    min-height: 525px;
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    @include tablet-view {
      padding: 20px 60px 60px;
    }

    @include extra-small-mobile {
      width: 90%;
      padding: 20px;
    }

    h1 {
      font-weight: normal;
      color: $light-grey;
      margin-bottom: 60px;
      font-size: $font-size-36;

      @include extra-small-mobile {
        font-size: 22px;
      }
    }

    p {
      letter-spacing: normal;
      line-height: 1.5;
      color: $light-grey;
    }
  }

  &__inputForm {
    background-color: $ghost-white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 230px;
    padding: 10px;
    width: 100%;

    @include desktop {
      flex-direction: row;
      height: 80px;
    }
  }

  &__input {
    margin-right: 20px;
  }

  &__updateContentBtnContainer {
    display: flex;
  }

  &__updateContentBtn {
    color: $lucky-point;
    font-size: $font-size-18;
    margin: 15px 20px 0px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__mediaContainerTitle,
  &__mediaContainerCategory,
  &__mediaContainerName {
    margin: auto 0;

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__mediaContainerCategory {
    margin-top: 10px;

    @include wide-mobile {
      margin: auto 0;
    }

    @include small-tablet {
      margin: auto 0;
    }
  }

  &__media {
    overflow-y: scroll;
    overflow-x: scroll;
    border-top: 2px solid $spun-pearl;
    width: 100%;

    @include wide-mobile {
      border-top: none;
    }

    @include small-tablet {
      border-top: none;
    }

    @include tablet {
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $whisper;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $spanish-grey;
      border-radius: 10px;
    }
  }

  &__mediaSubContainer {
    width: 100%;
    @include wide-mobile {
      display: grid;
      grid-template-columns: 2fr 2fr 3fr 1fr;
      grid-gap: 14px;
    }

    @include small-tablet {
      display: grid;
      grid-template-columns: 2fr 2fr 3fr 1fr;
      grid-gap: 14px;
    }
  }

  &__deleteBrick {
    &.edit {
      color: $fiery-rose;
      padding-right: 8px;
    }
  }

  &__loadingBtn {
    margin-top: 20px;
  }

  &__mediaContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 14px;
    border-bottom: 1px solid $spun-pearl;
    padding: 10px 0;
    font-size: $font-size-15;
    color: $light-grey;
    width: 100%;

    &.headers {
      border-top: 2px solid $spun-pearl;
      display: none;

      @include wide-mobile {
        display: grid;
        grid-template-columns: 2fr 2fr 3fr 1fr;
      }

      @include small-tablet {
        display: grid;
        grid-template-columns: 2fr 2fr 3fr 1fr;
      }
    }

    &--loading {
      display: grid;
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 25px;
      margin-bottom: 5px;
    }
  }
}

.expertNameInput {
  min-width: 130px;
}

.expertEmailInput {
  min-width: 240px;

  @include large-desktop {
    min-width: 340px;

    .error {
      border-color: red;
    }
  }

  .error {
    border-color: red;
  }
}
