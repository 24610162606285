@keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes successRise {
  0% {
    transform:  translateY(-50px);
  }
  100% {
   transform: translateY(-355px);
  }
}

@keyframes panelSuccess {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(-355px);
  }
}
  