@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-navigation {
  align-items: center;
  background-color: $guyabano;
  min-height: 64px;
  padding: 0 16px;
  max-width: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 15;
  justify-content: center;

  @include tablet {
    height: auto;
    max-height: initial;
    padding: 0 27px;
    justify-content: flex-end;
  }

  @include desktop {
    padding: 0 60px;
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    text-decoration: none;
  }

  &--shadow {
    box-shadow: 0 0 6px 0 $black-dormunt;
    top: 0 !important;
  }

  &__logoSmall {
    display: block;

    @include desktop {
      display: none;
    }
  }

  &__logoBig {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    height: 64px;
  }

  &__rightActions {
    display: flex;
    min-height: 40px;
    align-items: center;

    @include desktop {
      // flex: 1 1 100%;
    }
  }

  &__sendRecommend {
    display: block;
    font-size: 2rem;
    padding-right: 0;

    @include tablet {
      display: none;
    }
  }

  &__recommend {
    display: none;
    color: $black !important;

    @include tablet {
      display: flex;
      align-items: center;
      color: $nero;
    }

    .tmx-button__extraIcon {
      font-size: 2rem;
      padding-right: 0;
    }
  }

  &__link {
    &.tmx-button {
      padding: 0 10px;
    }

    @include tablet {
      padding: 0 16px;
    }
  }

  &__disableLink {
    &.tmx-button {
      font-size: $font-size-16;
      color: $lucky-point;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-weight: normal;
    }

    @include tablet {
      font-size: $font-size-15;
      padding: 0 16px;
    }

    &--helperText {
      &.tmx-button {
        color: $spun-pearl;
        display: none;
        cursor: default !important;

        @include tablet {
          display: flex;
        }
      }
    }
  }

  &__linkATag {
    font-size: $font-size-16;
    color: $lucky-point;
    display: flex;
    align-items: center;
    font-weight: normal;
    text-decoration: none;

    @include tablet {
      font-size: $font-size-15;
    }

    &--active {
      border-bottom: 2px solid $medium-purple;
      color: $lucky-point;
      margin-bottom: -7px;
      padding-bottom: 5px;
    }
  }
}
