@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-sellerMediaDetail {
  background: $white;
  padding: 10px;

  @include desktop {
    padding: 10px 183px;
  }

  &__companyName {
    font-size: $font-size-32;
    font-weight: normal;
    color: $dark-blue;
    text-align: center;
  }

  &__orgSettingsHeader {
    border-bottom: 1px solid $black;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-size: $font-size-22;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $nero;
      width: 42%;
    }

    &.error {
      border-bottom: 1px solid $fiery-rose;
    }
  }

  &__sellerInfo,
  &__detailsInfo {
    margin-top: 80px;
  }

  &__media {
    max-height: 220px;
    overflow-y: scroll;
    overflow-x: scroll;

    @include tablet {
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $whisper;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $spanish-grey;
      border-radius: 10px;
    }
  }

  &__mediaContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 41px;
    border-bottom: 1px solid $crayola-crystal;
    padding: 10px 0;

    &.priceOverrides {
      grid-template-columns: 1fr 1fr;
      border-bottom: none;
      padding: 10px 0 0 0;
    }

    

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 25px;
      margin-bottom: 5px;
    }
  }

  &__orgOwner {
    color: $gray71;
    margin-right: 7px;
  }

  &__mediaContainerName {
    margin: auto 0;

    &--bold {
      font-weight: 500;
    }

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__mediaContainerEmail {
    margin: auto 0;

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__priceInputContainer {
    margin: auto 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tmx-input__wrapper {
        width: 50%;
    }

    button {
      height: 35px;
      margin: initial;
      display: flex;
    }
  }

  &__mediaContainerAction {
    margin: auto 0px;
    text-align: left;
  }

  &__mediaContainerInviteBtn {
    text-align: left;
    margin-top: 8px;
  }

  &__btn {
    display: initial;
    height: 29px;
    text-align: center;
    margin: auto;
    font-size: $font-size-14;
  }

  &__sortIcon {
    display: inline;
    outline: none;
    cursor: pointer;
  }

  &__saveBtn {
    margin: 56px auto 0;
  }

  &__formInlineWrapper {
    margin-top: 24px;

    @include tablet {
      display: flex;
    }
  }

  &__formInline {
    flex: 0 0 50%;

    &.price {
      margin-top: 10px;
    }

    .tmx-dropDown.grey {
      color: $black;
      outline: none;
      padding: 4px 12px;
      width: 100%;
      height: 36px;
    }

    &.space {
      padding-right: 30px;
    }

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 57px;
    }
  }

  &__formTitle {
    font-size: $font-size-16;
    font-weight: 600;
    line-height: normal;
  }

  &__formDetails {
    font-size: $font-size-16;
    font-weight: normal;
    line-height: normal;
  }

  &__sliderWrapper {
    display: flex;
    align-items: center;
  }

  &__monthly {
    margin-right: 9px;
  }

  &__createButton {
    font-size: $font-size-16;

    &disabled {
      cursor: not-allowed;
    }
  }

  &__backButtonContainer {
    width: 100%;
    background: $white;
    text-align: center;
    padding: 150px 16px 0;
    z-index: 12;

    .tmx-button {
      font-size: $font-size-16;
    }

    @include tablet {
      padding: 110px 36px 0;
    }
  }

  &__backContent {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: 500;

    .icon {
      transform: rotate(90deg);
      padding-right: 0;
      padding-top: 8px;
    }

    @include desktop {
      margin-left: 0px;
    }
  }

  &__success {
    color: $dark-pastel-green;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__error {
    color: $radical-red;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__subFormDetails {
    margin-top: 16px;
  }

  &__fade {
    opacity: 0.3;
  }

  &__paymentEmail {
    margin-top: 12px;
  }

  &__customBtn {
    margin-left: auto;
  }

  &__accountInfo {
    margin-top: 40px;

    h1 {
      font-size: $font-size-18;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $nero;
    }
  }

  &__btn {
    margin-top: 20px;
  }

  &__backBtnContainer {
    width: 230px;

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 37px;
    }
  }

  &__complete {
    color: $nero
  }

  &__transfer {
    color: $nero
  }
 

  &__contentPriceContainer {
    width: 100%;
  }

  &__orgContainer {
    position: absolute;
    background: $white;
    width: 46%;
    padding: 0 8px;
    max-height: 200px;
    overflow-y: scroll;

    @include desktop {
      width: 500px;
    }
  }

  &__orgBtn {
    display: block;
    padding: 5px;
  }
}


