@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';
@import '../../../styles/variables/animation';

.tmx-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: $majorelle-blue;
  font-size: $font-size-18;
  line-height: $font-size-24;
  font-weight: 500;
  padding: 0;

  &.red {
    color: $red;
  }

  &.rose {
    color: $fiery-rose
  }

  &.black {
    color: $black;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.white {
    color: $white;
  }

  @include desktop {
    font-size: $font-size-14;
    line-height: $font-size-21;
  }

  &--blue {
    background-color: $majorelle-blue;
    border-radius: 4px;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: $font-size-18;
    font-weight: 500;
    padding: 0 20px;
    height: 44px;
    display: flex;
    align-items: center;
    outline: none;
    transition: border 0.4s ease 0s;

    // &.spinning {
    //   border: solid 4px $majorelle-blue;
    // }

    &.rounded {
      border-radius: 22px;
    }

    &.mobile-button {
      height: 36px;
      font-size: $font-size-14;
      padding: 0 12px;

      @include desktop {
        font-size: $font-size-18;
        padding: 0 20px;
        height: 44px;
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.success {
      background-color: $malachite;
      opacity: initial;
    }

    &:focus {
      background-color: $persian-blue;
    }

    &.secondary {
      border: solid 2px $majorelle-blue;
      background-color: $white;
      color: $majorelle-blue;

      &:hover {
        border: solid 4px $majorelle-blue;
      }

      &:focus {
        border: solid 4px $persian-blue;
        color: $persian-blue;

        .tmx-button__spinner::before {
          border-top: 2px solid $persian-blue;
          border-right: 2px solid transparent;
          animation: spinner 0.6s linear infinite;
        }
      }

      .tmx-button__spinner::before {
        border-top: 2px solid $majorelle-blue;
        border-right: 2px solid transparent;
        animation: spinner 0.6s linear infinite;
      }
    }

    &.red {
      border: solid 2px $red;
      color: $red;

      &:hover {
        border: solid 4px $red;
      }

      &:focus {
        border: solid 4px $red;
        color: $red;

        .tmx-button__spinner::before {
          border-top: 2px solid $red;
          border-right: 2px solid transparent;
          animation: spinner 0.6s linear infinite;
        }
      }
    }

    &.black {
      border: solid 2px $black;
      color: $black;

      &:hover {
        border: solid 4px $black;
      }

      &:focus {
        border: solid 4px $black;

        .tmx-button__spinner::before {
          border-top: 2px solid $black;
          border-right: 2px solid transparent;
          animation: spinner 0.6s linear infinite;
        }
      }
    }
  }

  &--grey {
    align-items: center;
    background-color: $granite-grey99;
    border-radius: 22px;
    font-size: $font-size-18;
    color: $white;
    padding: 0 32px;
    height: 44px;
    letter-spacing: 0.2px;
    outline: none;
    margin: 10px 0;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &--greyBlack {
    background-color: $cultured-light;
    border-radius: 4px;
    color: $black;
    font-size: $font-size-12;
    padding: 0 8px;
    letter-spacing: 0.2px;
    height: 24px;
    outline: none;
  }

  &--white {
    color: $majorelle-blue;
    background-color: $white;
    border-radius: 4px;
    font-size: $font-size-14;
    padding: 0 12px;
    letter-spacing: 0.2px;
    height: 36px;
    display: flex;
    align-items: center;
  }

  &--orange {
    background-color: $light-orange;
    border-radius: 4px;
    color: $black;
    font-size: $font-size-12;
    padding: 0 8px;
    letter-spacing: 0.2px;
    height: 24px;
    outline: none;
    color: $white;
  }

  &:hover {
    cursor: pointer;
  }

  &__spinner {
    position: relative;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    display: flex;

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border-top: 2px solid $hawkes-blue;
      border-right: 2px solid transparent;
      animation: spinner 0.6s linear infinite;
      top: 0;
      left: 0;
    }

    &-micro {
      display: inline;
      margin-right: 17px;
 
      &::before {
        width: 12px;
        height: 12px;
        top: 2px;
      }
    }
  }
  &__spinner-big{
    width: 40px !important;
    height: 40px !important;
    &::before {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-top: 2px solid $hawkes-blue;
      border-right: 2px solid transparent;
      animation: spinner 0.8s linear infinite;
    }
  }
  &__extraIcon {
    padding-right: 8px;
    outline: none;
  }
}
