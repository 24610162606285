@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/variables/animation';

.tmx-Checkbox {
  display: flex;

  &__wrapper {
    display: flex;
    user-select: none;
    cursor: inherit;
    align-items: center;
    white-space: nowrap;
  }

  &__innerWrapper {
    display: flex;
    height: 24px;
    width: 24px;
    line-height: 0;
    margin-right: 8px;
    order: 0;
    position: relative;
    cursor: pointer;

    &--small {
      width: 15px;
      height: 15px;
    }
  }

  &__input {
    bottom: 0;
    left: 50%;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 0;
    outline: 0;
    visibility: hidden;
  }

  &__ripple {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
    pointer-events: none;
    z-index: 1;

    &--small {
      width: 15px;
      height: 15px;
      left: calc(50% - 7px);
      top: calc(50% - 7px);
    }
  }

  &__frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
    border-width: 2px;
    border-style: solid;
    border-color: $majorelle-blue;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    pointer-events: none;
    color: $granite-grey99;

    &--small {
      border-color: $spanish-grey;
      border-width: 1px;
      z-index: 2;
    }
  }

  &__background {
    align-items: center;
    background-color: $white;
    display: inline-flex;
    justify-content: center;

    &--small {
      width: 15px;
      height: 15px;
      border-radius: 6px;
      position: absolute;
    }
  }

  &__icon {
    visibility: hidden;
    color: $majorelle-blue;
    padding-left: 5px;
    outline: none;

    &--visible {
      visibility: visible;
    }

    &--small {
      padding-left: 1px;
      padding-top: 1px;
    }
  }

  &__label {
    &--small {
      display: flex;
      align-items: center;
      font-size: $font-size-12;
    }
  }
}
