@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-search {
  &__search {
    border-radius: 4px;
    border: solid 1px $spanish-grey;
    background-color: $white;
    padding-left: 30px;

    &.searchInput {
      padding-left: 30px;
      border-radius: 4px;
      border: solid 1px $light-grey-blue;
      background-color: $white;
    }

    &::placeholder {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $spun-pearl;
      font-size: $font-size-15;
    }

    &:focus {
      border: 1px solid $light-grey;;
    }
  }

  .tmx-input__viewIconCta {
    left: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: $spun-pearl;
  }
}
