@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-marketingCarousel {
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
  background: $white;
  padding: 10px;
  font-family: 'IBM Plex Sans', sans-serif;

  @include mobile {
    width: 95%;
    margin: 52px auto;
    padding: 40px 20px 100px;
  }

  @include small-tablet {
    width: 95%;
    margin: 52px auto;
    padding: 40px 20px 100px;
  }

  @include tablet {
    width: 95%;
    margin: 52px auto;
    padding: 40px 60px 100px;
    height: 650px;
    overflow: scroll;
  }

  @include desktop {
    width: 840px;
    margin: 52px auto;
    padding: 40px 80px 100px;
  }

  &__companyName {
    font-size: $font-size-32;
    font-weight: normal;
    color: $dark-blue;
    text-align: center;
  }

  &__previewHeader {
    margin: 0px 10px;
    background: $white;

    @include desktop {
      margin: 0px 183px;
    }
  }

  &__header,
  &__previewHeader {
    text-align: center;

    h1 {
      font-size: $font-size-36;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: normal;
      text-align: center;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: $light-grey;
    }

    &.error {
      border-bottom: 1px solid $fiery-rose;
    }
  }

  &__sellerInfo,
  &__detailsInfo {
    margin-top: 20px;
  }

  &__detailsInfo {
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }

  &__media {
    // max-height: 220px;
    overflow-y: scroll;
    overflow-x: scroll;
    border-top: 2px solid $spun-pearl;

    @include wide-mobile {
      border-top: none;
    }

    @include small-tablet {
      border-top: none;
    }

    @include tablet {
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $whisper;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $spanish-grey;
      border-radius: 10px;
    }
  }

  &__mediaSubContainer {
    @include wide-mobile {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      grid-gap: 14px;
    }

    @include small-tablet {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      grid-gap: 14px;
    }

    &.addNewCarousel {
      grid-template-columns: 5fr 1fr;
    }
  }

  &__mediaContainer {
    display: grid;
    grid-template-columns: 1fr 20fr;
    grid-gap: 14px;
    border-bottom: 1px solid $spun-pearl;
    padding: 10px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: $font-size-15;
    color: $light-grey;

    @include wide-mobile {
      grid-template-columns: 2fr 5fr;
    }

    @include small-tablet {
      grid-template-columns: 2fr 5fr;
    }

    &.headers {
      border-top: 2px solid $spun-pearl;
      display: none;

      @include wide-mobile {
        display: grid;
      }

      @include small-tablet {
        display: grid;
      }
    }

    &.newCarousel {
      display: grid;
      grid-template-columns: 1fr 20fr;
      border-bottom: none;
      padding: 10px 0;
      background-color: $ghost-white;

      @include wide-mobile {
        grid-template-columns: 2fr 5fr;
      }

      @include small-tablet {
        grid-template-columns: 2fr 5fr;
      }
    }

    &--loading {
      display: grid;
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 25px;
      margin-bottom: 5px;
    }
  }

  &__orgOwner {
    color: $gray71;
    margin-right: 7px;
  }

  &__mediaContainerPosition {
    margin-left: 13px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 70%;

    @include wide-mobile {
      align-items: center;
    }

    @include small-tablet {
      align-items: center;
    }

    &--bold {
      font-weight: 500;
    }

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }

    button {
      margin-left: 20px;
      display: none;

      @include wide-mobile {
        display: initial;
      }

      @include small-tablet {
        display: initial;
      }

      &:disabled {
        color: $gray72;
      }
    }
  }

  &__mediaContainerTitle,
  &__mediaContainerCategory,
  &__mediaContainerName {
    margin: auto 0;

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__mediaContainerCategory {
    margin-top: 10px;

    @include wide-mobile {
      margin: auto 0;
    }

    @include small-tablet {
      margin: auto 0;
    }
  }

  &__categoryInputContainer {
    margin: auto 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tmx-input__wrapper {
      width: 50%;
    }

    button {
      height: 35px;
      margin: initial;
      display: flex;
    }
  }

  &__mediaContainerAction {
    text-align: left;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    @include wide-mobile {
      margin: auto 0px;
    }

    @include small-tablet {
      margin: auto 0px;
    }
  }

  &__sortButtonsContainer {
    display: block;

    @include wide-mobile {
      display: none;
    }

    @include small-tablet {
      display: none;
    }
  }

  &__DownSortBtn {
    margin-left: 15px;

    &:disabled {
      color: $gray72;
    }
  }

  &__UpSortBtn {
    &:disabled {
      color: $gray72;
    }
  }

  &__createCarouselBtn {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  &__sortIcon {
    display: inline;
    outline: none;
    cursor: pointer;
  }

  &__updateContent {
    margin: 56px auto 0;
  }

  &__formInlineWrapper {
    margin-top: 24px;

    @include tablet {
      display: flex;
    }
  }

  &__formInline {
    flex: 0 0 50%;

    &.price {
      margin-top: 10px;
    }

    .tmx-dropDown.grey {
      color: $black;
      outline: none;
      padding: 4px 12px;
      width: 100%;
      height: 36px;
    }

    &.space {
      padding-right: 30px;
    }

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 57px;
    }
  }

  &__formTitle {
    font-size: $font-size-16;
    font-weight: 600;
    line-height: normal;
  }

  &__formDetails {
    font-size: $font-size-16;
    font-weight: normal;
    line-height: normal;
  }

  &__sliderWrapper {
    display: flex;
    align-items: center;
  }

  &__monthly {
    margin-right: 9px;
  }

  &__refreshButton {
    font-size: $font-size-15;
    margin-left: 12px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: $lucky-point;
    display: flex;
    align-items: center;

    span {
      padding-right: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__backButtonContainer {
    width: 100%;
    text-align: center;
    padding: 150px 16px 0;
    z-index: 12;

    .tmx-button {
      font-size: $font-size-16;
    }

    @include tablet {
      padding: 110px 36px 0;
    }
  }

  &__backContent {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;

    .icon {
      font-family: 'IBM Plex Sans', sans-serif;
      padding-right: 10px;
      transform: rotate(180deg);
      color: $spun-pearl;
    }

    @include desktop {
      margin-left: 0px;
    }
  }

  &__success {
    color: $dark-pastel-green;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__error {
    color: $radical-red;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__subFormDetails {
    margin-top: 16px;
  }

  &__fade {
    opacity: 0.3;
  }

  &__paymentEmail {
    margin-top: 12px;
  }

  &__customBtn {
    margin-left: auto;
  }

  &__accountInfo {
    margin-top: 40px;

    h1 {
      font-size: $font-size-18;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $nero;
    }
  }

  &__btn {
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 15px;
    color: $white;
    font-size: $font-size-18;
    align-items: center;
    display: flex;
    height: 44px;

    @include small-tablet {
      padding: 15px 38px;
    }
  }

  &__updateContentBtnContainer {
    display: flex;
  }

  &__updateContentBtn {
    font-family: 'IBM Plex Sans', sans-serif;
    color: $lucky-point;
    font-size: $font-size-18;
    margin: 15px 20px 0px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__backBtnContainer {
    width: 850px;
    margin: auto;

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 37px;
    }
  }

  &__deleteBrick {
    color: $fiery-rose;
  }

  &__contentPriceContainer {
    width: 100%;
  }

  &__orgContainer {
    position: absolute;
    background: $white;
    width: 46%;
    padding: 0 8px;
    max-height: 200px;
    overflow-y: scroll;

    @include desktop {
      width: 500px;
    }
  }

  &__orgBtn {
    display: block;
    padding: 5px;
  }

  &__sliderSection {
    .slick-dots {
      display: flex;
      justify-content: center;
      margin: 0;
      // padding: 1rem 0;
      list-style-type: none;
      li {
        margin: 0 0.25rem;
      }
      button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: $solitude;
        text-indent: -9999px;
      }
      li.slick-active button {
        background-color: $light-slate-grey;
        width: 30px;
        height: 10px;
        margin: 0 -10px !important;
        border-radius: 5px;
      }
    }
  }

  &__dropDownContainer {
    margin-bottom: 18px;
    background: $white;

    p {
      margin: 4px 0;
      color: $dark-grey;
      font-size: $font-size-16;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
    }

    .tmx-dropDown.grey {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      color: $dark-grey;
      outline: none;
      padding: 4px 12px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px $lighter-grey;

      &:disabled {
        cursor: not-allowed;
      }

      @include tablet {
        width: 248px;
      }
    }
  }

  &__contentUrlInput {
    &.tmx-input.dark {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
      border: solid 1px $medium-purple;
    }
  }

  &__contentURLInputLabel {
    color: $dark-grey;
    font-size: $font-size-15;
    font-weight: 500;
  }
}
