@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
.upload_success_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .media_link {
    margin-top: 0 !important;
    a {
      color: $medium-purple;
      cursor: pointer;
    }
  }

  p {
    text-align: center;
  }

  .upload_another {
    display: flex;
    align-items: center;
    background: $ghost-white;
    border-radius: 30px;
    color: white;
    padding: 15px 28px;
    font-size: 20px;
    color: $granite-grey;
    cursor: pointer;
  }
}
