@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-newsroomsUsing {
  &__linkInput {
    margin-bottom: 20px;

    @include desktop {
      width: 400px;
    }
  }

  &__linkInputLabel {
    margin-bottom: 5px;
  }

  &__newsroomDropdown {
    margin-bottom: 20px;
  }

  &__labelDeleteWrapper {
    display: flex;
  }

  &__labelWrapper {
    font-size: $font-size-15;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 5px;
    padding-top: 5px;
    margin-right: 174px;

    @include desktop {
      margin-right: 236px;
    }
  }

  &__description {
    @include desktop {
      width: 400px;
      min-height: 72px;
      max-height: 250px;
      overflow-y: auto;
    }
  }


}
