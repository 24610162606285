@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/mixins/mediaqueries';

.tmx-uploadFormContainer {
  padding: 80px 88px 30px;
  width: 1100px;
  margin: 0 auto;
  color: $granite-grey;
  display: flex;
  justify-content: center;

  .tmx-uploadForm {
    padding-top: 40px;
  }

  @include extra-small-mobile {
    width: 100vw;
    padding: 100px 10px 30px;
  }

  @include tablet-view {
    width: 100vw;
    padding: 80px 40px 30px;
  }

  .title-field {
    .tmx-textArea__counterPara {
      display: block;
    }
  }

  &__previewThumbnail {
    height: 350px;
    width: 300px;
    position: fixed;
    left: 1030px;
    top: 360px;
    background: white;
    border-radius: 15px;
    z-index: 10;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);

    @include tablet-view {
      left: 60vw;
      top: 500px;
    }

    @include extra-small-mobile {
      top: 300px;
      left: 15vw;
    }

    @include large-desktop {
      left: 1150px;
    }

    &__heading {
      background: $light-silver;
      height: 40px;
      width: 100%;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      &__close {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        right: -8px;
        cursor: pointer;
        padding-left: 7px;
      }
    }

    &__content {
      background: $ghost-white;
      height: 200px;
      width: 100%;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;

      &__one {
        width: 80%;
        height: 10px;
        background: $ghost-white;
      }

      &__two {
        width: 60%;
        height: 10px;
        background: $ghost-white;
        margin-top: 20px;
      }

      &__three {
        width: 40%;
        height: 10px;
        background: $ghost-white;
        margin-top: 20px;
      }
    }
  }

  &__sectionTitle {
    font-size: $font-size-24;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $granite-grey99;
    margin-bottom: 30px;
  }

  .tmx-textArea__counterPara {
    display: none;
  }

  textarea:focus,
  input:focus,
  select:focus {
    outline: none;
    border-color: $faint-grey !important;
  }

  &__uploadcontainer {
    background: $white;
    padding: 20px 134px 60px;
    width: 100%;
    min-height: 600px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);

    @include extra-small-mobile {
      padding: 20px 15px;
      width: 320px;
    }

    @include tablet-view {
      padding: 20px;
    }

    &__wrapper {
      width: 68%;
      margin: 0 auto;

      @include extra-small-mobile {
        width: 95%;
      }

      .small-thumbnail {
        background: $athens-gray;
        padding: 10px !important;
        border-radius: 50%;
      }

      .big-thumbnail {
        border-radius: 22px;
      }

      .preview {
        position: absolute;
        left: 1080px;
        top: 600px;
        background: $ghost-white;
        color: $granite-grey !important;
        box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
        padding: 0 22px 0 5px;
        // display: none;
        display: flex;
        align-items: center;
        font-size: $font-size-14;
        font-family: 'IBM Plex Sans', sans-serif;

        @include tablet-view {
          position: fixed;
          left: 73vw;
          top: 650px;
        }

        @include extra-small-mobile {
          top: 520px;
          left: 80vw;
          z-index: 10;
        }

        @include large-desktop {
          left: 68vw;
        }
      }

      .preview-disabled {
        cursor: not-allowed;
      }

      .tmx-input__wrapper {
        border-color: $faint-grey !important;
        border-radius: 4px;
      }
    }
  }

  textarea,
  input,
  select {
    border-color: $faint-grey !important;
    font-size: 0.9375rem;
    color: $dark-grey !important;
    border-radius: 4px;
  }

  .top-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: $font-size-36;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: $granite-grey99;
    }

    .MuiGrid-grid-xs-12 {
      display: flex;
      justify-content: center;
      width: 800px;
      max-width: unset;
    }
  }

  .cleared-article-radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 36px;
    margin-bottom: 20px;

    .MuiGrid-grid-xs-12 {
      display: flex;
      justify-content: center;
      width: 800px;
      max-width: unset;
    }
  }

  .cleared-content-radio {
    margin-bottom: 20px;
  }

  .tmx-fileUpload {
    margin-bottom: 20px;
  }

  .add-contact {
    display: flex;
    align-items: center;
    color: $lucky-point;
    font-size: $font-size-14;
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 20px 0;
  }
  .notify {
    margin-bottom: 20px;
  }
  .tags {
    margin-bottom: 20px;

    @include desktop {
      width: 400px;
    }

    .location-tag {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $granite-grey;
  }

  .tmx-Checkbox__icon {
    color: $granite-grey;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $light-grey;
  }

  .tmx-Checkbox__icon {
    color: $light-grey;
  }

  .MuiAutocomplete-root {
    label.Mui-focused {
      color: $light-grey !important;
    }
    ::placeholder {
      color: red !important;
    }
  }

  .Mui-focused {
    border-color: $faint-grey !important;
    border-width: 1px;
    fieldset {
      border: 1px solid $faint-grey !important;
    }
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: $faint-grey;
    }
    &:hover fieldset {
      border: 1px solid $faint-grey !important;
    }
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
}
