@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-expertProfileSettings {
  padding: 155px 16px 0;
  font-family: 'IBM Plex Sans', sans-serif;
  padding-top: 4px;
  padding-right: 12px;
  padding-left: 10px;

  &--oveflow {
    height: 600px;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    width: 10px;
    // border-radius: 10px;
    background-color: $H240;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $spun-pearl;
    border-radius: 10px;
    &:hover {
      background-color: $waterloo;
      cursor: pointer;
    }
  }

  @include tablet {
    width: 405px;
    margin: auto;
    padding: 88px 0;
    padding-top: 4px;
    padding-right: 12px;
    padding-left: 10px;
  }

  &__title {
    font-size: $font-size-28;
    margin-bottom: $font-size-36;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: $light-grey;

    @include tablet {
      font-size: $font-size-32;
    }
  }

  &__count {
    color: $gray71;
    font-size: $font-size-18;
    text-align: center;
  }

  &__result {
    margin-top: 24px;
    height: 600px;
    padding: 3px;

    &--scroll {
      overflow-x: hidden;
      height: 900px;
      &::-webkit-scrollbar {
        width: 10px;
        // border-radius: 10px;
        background-color: $H240;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $spun-pearl;
        border-radius: 10px;
        &:hover {
          background-color: $waterloo;
          cursor: pointer;
        }
      }
    }
  }

  &__content {
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    height: 50px;
    padding: 11px 30px;

    &.search {
      height: initial;
      padding: 11px 30px;
    }

    &--pending {
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
    }
  }

  &__contentTitle {
    color: $light-grey;
    font-size: $font-size-24;
    font-weight: normal;
    text-align: left;

    &.search {
      word-break: break-all;
    }
  }

  &__userDetails {
    &.search {
      word-break: break-all;
      margin-top: 8px;
      line-height: 1.53;
    }
  }

  &__userProperty {
    font-size: $font-size-15;
    color: $dark-grey;
    line-height: normal;
    word-break: break-all;
  }

  &__searchWrapper {
    margin-bottom: 30px;
  }

  &__mock {
    background-color: $light-grey;
    height: 22px;
    width: 80%;
    margin: auto;
  }

  &__mockContent {
    background-color: $light-grey;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    height: 50px;
  }

  &__subTitle {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5d5d74;
    border-bottom: 1px solid #aeaebd;
    margin-bottom: 26px;
  }

  &__overallContainer {
    padding: 100px 160px;
  }

  &__buttonTextContainer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 0px;
  }

  &__buttonDetailsTitle {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5d5d74;
  }

  &__buttonDetailsSubTitle {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #5d5d74;
  }

  &__shell {
    width: 100%;
    background-color: $white;
    padding: 91px;
    padding-top: 15px;
    border-radius: 20px;
    height: auto;
  }
}
