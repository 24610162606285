@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-contactFormWrapper {
  background: $athens-gray;
  padding: 20px 10px;

  &

  .close_contact {
    float: right;
    color: $granite-grey;
    cursor: pointer;
  }

  h1 {
    color: $granite-grey99;
    font-weight: normal;
    font-size: 24px;
  }

  .tmx-contactContainer {
    margin-top: 20px;

    .add_field {
      display: flex;
      align-items: center;
      color: $lucky-point;
      font-size: $font-size-14;
      font-family: 'IBM Plex Sans', sans-serif;
      background: $light-gray;
      background: $athens-gray;
      box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.4);
      border: 1px solid $athens-gray;
      border-radius: 3px;
      padding-right: 10px;
    }

    .tmx-contactInput {
      margin-bottom: 15px;
    }

    .tmx-contactForm__contactData {
      display: flex;
      flex-direction: column;
      align-items: space-between;
      width: 100%;

      .tmx-contactForm {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        width: 100%;

        @include extra-small-mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        &__contactInfo {
          width: 100%;
        }

        .textInput {
          width: 100px;
          min-width: 55%;

          @include extra-small-mobile {
            width: 100%;
          }

          .number_notes {
            display: flex;

            div:first-child {
              width: 100%;
            }
          }
        }
        &_email {
          display: flex;
          flex-direction: column;
          max-width: 60%;
          min-width: 40%;

          @include extra-small-mobile {
            width: 100%;
            max-width: 100%;
          }

          .tmx-contactForm_label {
            margin-bottom: 8px;
          }
        }

        &__orgDropDown {
          width: 100%;
          padding: 8px 14px;
          height: 38px;
          border-radius: 4px;
          font-size: $font-size-16;

          @include extra-small-mobile {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
