@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-emailAlerts {
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
  background: $white;
  padding: 10px;
  font-family: 'IBM Plex Sans', sans-serif;

  @include mobile {
    width: 95%;
    margin: 52px auto;
    padding: 40px 20px 100px;
  }

  @include small-tablet {
    width: 95%;
    margin: 52px auto;
    padding: 40px 20px 100px;
  }

  @include tablet {
    width: 95%;
    margin: 52px auto;
    padding: 40px 60px 100px;
  }

  @include desktop {
    width: 840px;
    margin: 52px auto;
    padding: 40px 80px 100px;
  }

  &__dropLabel {
    font-size: $font-size-16;
    color: $dark-grey;
  }

  &__dropDownContainer {
    margin-bottom: 18px;
    background: $white;

    p {
      margin: 4px 0;
      color: $dark-grey;
      font-size: $font-size-16;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
    }

    .tmx-dropDown.grey {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      color: $dark-grey;
      outline: none;
      padding: 4px 12px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px $lighter-grey;

      &:disabled {
        cursor: not-allowed;
      }

      @include tablet {
        width: 248px;
      }
    }
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
    color: $light-slate-grey;
  }

  &__panelParagraph {
    font-size: 18px;
    font-weight: normal;
    color: $light-slate-grey;
    padding: 40px 0;
  }

  &__panelText {
    padding: 30px 0px;
  }

  &__mediaSubContainer {
    @include wide-mobile {
      display: grid;
      grid-template-columns: 10fr 1fr;
      grid-gap: 14px;
    }

    @include small-tablet {
      display: grid;
      grid-template-columns: 10fr 1fr;
      grid-gap: 14px;
    }

    &.addNewCarousel {
      grid-template-columns: 5fr 1fr;
    }
  }

  &__mediaContainer {
    display: grid;
    grid-template-columns: 1fr 20fr;
    grid-gap: 14px;
    border-bottom: 1px solid $spun-pearl;
    padding: 10px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: $font-size-15;
    color: $light-grey;

    @include wide-mobile {
      grid-template-columns: 2fr 5fr;
    }

    @include small-tablet {
      grid-template-columns: 2fr 5fr;
    }

    &.headers {
      border-top: 2px solid $spun-pearl;
      display: none;

      @include wide-mobile {
        display: grid;
      }

      @include small-tablet {
        display: grid;
      }
    }

    &.newCarousel {
      display: grid;
      grid-template-columns: 1fr 20fr;
      border-bottom: none;
      padding: 10px 0;
      background-color: $ghost-white;

      @include wide-mobile {
        grid-template-columns: 2fr 5fr;
      }

      @include small-tablet {
        grid-template-columns: 2fr 5fr;
      }
    }

    &--loading {
      display: grid;
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 25px;
      margin-bottom: 5px;
    }
  }

  &__mediaContainerPosition {
    margin-left: 13px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 70%;

    @include wide-mobile {
      align-items: center;
    }

    @include small-tablet {
      align-items: center;
    }

    &--bold {
      font-weight: 500;
    }

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }

    button {
      margin-left: 20px;
      display: none;

      @include wide-mobile {
        display: initial;
      }

      @include small-tablet {
        display: initial;
      }

      &:disabled {
        color: $gray72;
      }
    }
  }

  &__header,
  &__previewHeader {
    text-align: center;

    h1 {
      font-size: $font-size-36;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: normal;
      text-align: center;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: $light-grey;
    }

    &.error {
      border-bottom: 1px solid $fiery-rose;
    }
  }

  &__mediaContainerTitle,
  &__mediaContainerCategory,
  &__mediaContainerName {
    margin: auto 0;

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__mediaContainerAction {
    text-align: left;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    @include wide-mobile {
      margin: auto 0px;
    }

    @include small-tablet {
      margin: auto 0px;
    }
  }

  &__titleInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tmx-input__wrapper {
      width: 50%;
    }

    button {
      height: 35px;
      margin: initial;
      display: flex;
    }
  }

  &__backButtonContainer {
    width: 100%;
    text-align: center;
    padding: 150px 16px 0;
    z-index: 12;

    .tmx-button {
      font-size: $font-size-16;
    }

    @include tablet {
      padding: 110px 36px 0;
    }
  }

  &__backBtnContainer {
    width: 850px;
    margin: auto;

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 37px;
    }
  }

  &__backContent {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;

    .icon {
      font-family: 'IBM Plex Sans', sans-serif;
      padding-right: 0px;
      transform: rotate(180deg);
      color: $spun-pearl;
    }

    @include desktop {
      margin-left: 0px;
    }
  }

  &__deleteBrick {
    color: $fiery-rose;
  }

  &__btn {
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 15px 0px;
    color: $white;
    font-size: $font-size-18;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    height: 44px;

    @include small-tablet {
      width: 250px;
      margin-right: 20px;
    }
  }

  &__sendButtonContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
  }

  &__joinTMXButton {
    font-size: $font-size-18;
    height: initial;
    padding: 11.7px 82px;
    align-items: baseline;
    color: $white;
    background-color: $light-slate-grey;
    border-radius: 22px;
    margin-right: 20px;

    @include tablet {
      padding: 11.7px 90px;
    }
  }

  &__accountSuccess {
    top: 618px;
    animation: panelSuccess 0.8s ease forwards;
    position: relative;

    @include desktop {
      top: 570px;
    }
  }

  &__successCheckMarkContainer {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: $light-green;
    margin: auto;
    padding: 10px;
  }

  &__check {
    color: $white;
  }

  &__accountCreated {
    text-align: center;
    font-size: $font-size-20;
    color: $light-slate-grey;
  }

  &__sortButtonsContainer {
    display: block;

    @include wide-mobile {
      display: none;
    }

    @include small-tablet {
      display: none;
    }
  }

  &__DownSortBtn {
    margin-left: 15px;

    &:disabled {
      color: $gray72;
    }
  }

  &__UpSortBtn {
    &:disabled {
      color: $gray72;
    }
  }

  &__updateContentBtnContainer {
    display: flex;
  }

  &__updateContentBtn {
    font-family: 'IBM Plex Sans', sans-serif;
    color: $lucky-point;
    font-size: $font-size-18;
    margin: 15px 20px 0px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__contentUrlInput {
    &.tmx-input.dark {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
      border: solid 1px $medium-purple;
    }
  }

  &__contentURLInputLabel {
    color: $dark-grey;
    font-size: $font-size-15;
    font-weight: 500;
  }
}
