@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-dropDown {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $white;
  background-position-x: 100%;
  background-position-y: 5px;
  background-repeat: no-repeat;
  border: none;
  font-size: $font-size-15;
  font-weight: normal;
  outline: none;
  padding: 8px 12px;
  width: 100%;
  background-position: right;
  background-origin: content-box;
  height: 38px;

  &::placeholder {
    color: $spun-pearl;
  }

  &.grey {
    border: 1px solid $faint-grey;
    border-radius: 4px;

    &::placeholder {
      color: $spun-pearl;
    }

    @include tablet {
      width: 400px;
    }
  }

  &:focus {
    outline: none;
  }
}
