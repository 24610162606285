@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/mixins/mediaqueries';

.tmx-uploadOrg {
  padding-top: 100px;
  width: 1000px;
  margin: 0 auto;
  min-height: 600px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: $granite-grey;

  @include tablet-view {
    width: 100vw;
    padding: 80px 40px 30px;
  }

  @include extra-small-mobile {
    width: 100vw;
    padding: 100px 10px;
  }

  &__SelectOrg {
    width: 100%;
    select {
      border-color: $faint-grey !important;
      font-size: 0.9375rem;
      color: $granite-grey;
      border-radius: 4px;
    }

    &__label {
      margin-bottom: 5px;
    }
  }

  &__Container {
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    background: $white;
    padding: 20px 140px 60px;
    margin: 0 auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    @include tablet-view {
      padding: 20px 60px 60px;
    }

    @include extra-small-mobile {
      width: 90%;
      padding: 20px;
    }

    h1 {
      font-weight: normal;
      color: $light-grey;
      margin-bottom: 60px;
      @include extra-small-mobile {
        font-size: 22px;
      }
    }

    p {
      letter-spacing: normal;
      line-height: 1.5;
      color: $light-grey;
    }
  }

  &__duplicateOrg {
    color: red !important;
  }

  &__OrgAdded {
    color: $malachite !important;
  }

  &__button {
    background: $deep-grey;
    border-radius: 30px;
    color: $white;
    padding: 10px 28px;
    width: 100%;
    margin-top: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
