  @mixin small-mobile {
    @media (min-width: 320px) {
      @content;
    }
  }
  
  @mixin mobile {
    @media (min-width: 360px) {
      @content;
    }
  }
  
  @mixin small-wide-mobile {
    @media (min-width: 375px) and (max-width: 420px){
      @content;
    }
  }
  
  @mixin mid-wide-mobile {
    @media (min-width: 435px) and (max-width: 631px){
      @content;
    }
  }
  
  @mixin wide-mobile {
    @media (min-width: 632px) and (max-width: 767px){
      @content;
    }
  }
  
  @mixin small-tablet {
    @media (min-width: 666px) {
      @content;
    }
  }
  
  @mixin tablet {
    @media (min-width: 768px) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }
  
  @mixin large-desktop {
    @media (min-width: 1500px) {
      @content;
    }
  }
  
  @mixin tablet-view {
    @media (min-width: 500px) and (max-width: 950px){
      @content;
    }
  }
  @mixin extra-small-mobile {
    @media (min-width: 320px) and (max-width: 500px) {
      @content;
    }
  }

  @mixin small-screen {
    @media (max-width : 500px) {
      @content
    }
  }