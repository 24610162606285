@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-dashboard {
  padding: 118px 16px 63px 16px;

  @include desktop {
    padding: 100px 160px;
  }

  &__title {
    text-align: center;
    font-size: $font-size-24;
    color: $granite-grey99;
    font-weight: normal;

    &--titleBelow {
      padding-top: 30px;
    }

    @include tablet {
      font-size: $font-size-36;
    }
  }

  &__iframe {
    width: 100%;
    height: 568px;
  }

  &__overallContainer {
    background-color: $white;
    padding: 40px;
    padding-top: 15px;
    border-radius: 20px;

    @include tablet {
      padding: 15px 82px 372px 82px;
    }
  }
}
