@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-modal {
  align-items: center;
  background-color: $black-alpha;
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  justify-content: center;

  &__back {
    display: flex;
    font-size: $font-size-14;
    align-items: center;
  }

  &__content {
    font-weight: 500;
    line-height: 21px;
    margin: 0 auto;
    padding: 36px 16px 16px;
    overflow-y: auto;
    height: calc(100% - 160px);

    @include desktop {
      height: calc(100% - 200px);
      padding: 36px;
    }

    &--large {
      height: 100%;
      overflow-y: auto;
      padding: 0 16px 16px;

      @include desktop {
        width: auto;
        height: calc(100% - 80px);
        padding: 36px;
      }

      &--withoutHeader {
        height: calc(100% - 186px);
      }
    }

    &--withBack {
      height: calc(100% - 56px);
    }

    &--withBackAndHeader {
      height: calc(100% - 100px);
      position: relative;
    }
  }

  &__header {
    background: linear-gradient(to right, $duke-blue 0%, $majorelle-blue 99%);
    font-size: $font-size-24;
    line-height: $font-size-32;
    color: $white;
    padding: 0 16px;
    width: 100%;
    z-index: 10;
    height: 56px;
    display: flex;
    align-items: flex-end;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include desktop {
      border-radius: 6px 6px 0 0;
      padding: 0 24px;
      position: relative;
      width: 784px;
      background-color: $white;
      margin: 0 auto;
    }

    &__closeIcon {
      color: $granite-grey;
      text-align: right;
      position: relative;
    }

    &__backButton {
      font-weight: 500;
      line-height: normal;
      font-size: $font-size-14;
      padding: 6px 20px;
      box-shadow: 0px 2px 9px 0px rgba(128,124,124,0.79);
      -webkit-box-shadow: 0px 2px 9px 0px rgba(128,124,124,0.79);
      -moz-box-shadow: 0px 2px 9px 0px rgba(128,124,124,0.79);
      margin-bottom: 10px;

      .icon {
        transform: rotate(90deg);
        padding-right: 0;
        padding-top: 8px;
      }
    }

    img {
      width: 128px;
      height: 41px;
    }
  }

  &__wrapper {
    height: 100%;
    background-color: $white;
    width: 100%;

    @include tablet {
      margin: 35px auto 80px;
      height: auto;
      border-radius: 6px;
      width: 480px;
    }

    &--large {
      height: 100%;

      @include tablet {
        margin: 35px auto 80px;
        height: calc(100% - 115px);
      }
    }
  }

  &__header-name {
    flex: 0 0 50%;
    position: absolute;
    z-index: 12;

    @include desktop {
      position: initial;
    }
  }

  &__close-wrapper {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: -50%;

    &__close {
      cursor: pointer;
      outline: none;
      border: none;
      padding: 15px;
      margin-left: -10px;

      @include desktop {
        padding: 0;
        margin-left: 0;
      }
    }

    @include desktop {
      position: initial;
      right: 0;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    outline: none;
    border: none;

  }

  &__logoContainer {
    padding: 48px 0 24px;

    @include desktop {
      padding: 48px 0 32px;
    }
  }

  &__logoWrapper {
    &.logo {
      text-align: center;
      font-size: $font-size-48;

      @include desktop {
        font-size: $font-size-60;
      }
    }
  }

  &__title {
    font-size: $font-size-28;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.2px;
    margin: 0 16px;
    line-height: 1.43;

    @include desktop {
      font-size: $font-size-32;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
}
