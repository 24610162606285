@font-face {
  font-family: 'tmx-icons';
  src: url('/fonts/tmx-icons/tmx-icons.eot');
  src: url('/fonts/tmx-icons/tmx-icons.eot#iefix') format('embedded-opentype'),
    url('/fonts/tmx-icons/tmx-icons.ttf') format('truetype'),
    url('/fonts/tmx-icons/tmx-icons.woff') format('woff'),
    url('/fonts/tmx-icons/tmx-icons.svg#tmx-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='tmx-icon-'],
[class*=' tmx-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tmx-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tmx-icon-logo-small {
  .path1:before {
    content: '\e90d';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e90e';
    margin-left: -1.9501953125em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e90f';
    margin-left: -1.9501953125em;
    color: rgb(89, 79, 254);
  }
}
.tmx-icon-logo-big {
  .path1:before {
    content: '\e911';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e912';
    margin-left: -4.392578125em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e913';
    margin-left: -4.392578125em;
    color: rgb(89, 79, 254);
  }
  .path4:before {
    content: '\e914';
    margin-left: -4.392578125em;
    color: rgb(45, 45, 79);
  }
}
.tmx-icon-acquired:before {
  content: '\e92d';
}
.tmx-icon-trash:before {
  content: '\e92c';
}
.tmx-icon-link-solid:before {
  content: '\e92a';
}
.tmx-icon-eye-slash-solid:before {
  content: '\e92b';
}
.tmx-icon-views:before {
  content: '\e903';
}
.tmx-icon-user:before {
  content: '\e915';
}
.tmx-icon-photo-gallery:before {
  content: '\e929';
}
.tmx-icon-uploaded:before {
  content: '\e916';
}
.tmx-icon-target:before {
  content: '\e917';
}
.tmx-icon-sign-out:before {
  content: '\e918';
}
.tmx-icon-share:before {
  content: '\e919';
}
.tmx-icon-settings:before {
  content: '\e91a';
}
.tmx-icon-search1:before {
  content: '\e91b';
}
.tmx-icon-recommended:before {
  content: '\e91c';
}
.tmx-icon-payment:before {
  content: '\e91d';
}
.tmx-icon-notification:before {
  content: '\e91e';
}
.tmx-icon-metadata:before {
  content: '\e91f';
}
.tmx-icon-message:before {
  content: '\e920';
}
.tmx-icon-home:before {
  content: '\e921';
}
.tmx-icon-flag:before {
  content: '\e922';
}
.tmx-icon-file-error:before {
  content: '\e923';
}
.tmx-icon-ellipsis:before {
  content: '\e924';
}
.tmx-icon-downloaded:before {
  content: '\e925';
}
.tmx-icon-contact:before {
  content: '\e926';
}
.tmx-icon-check1:before {
  content: '\e927';
}
.tmx-icon-blocked:before {
  content: '\e928';
}
.tmx-icon-x-circled:before {
  content: '\e900';
}
.tmx-icon-retry-circled:before {
  content: '\e901';
}
.tmx-icon-question:before {
  content: '\e902';
}
.tmx-icon-card:before {
  content: '\e903';
}
.tmx-icon-check:before {
  content: '\e904';
}
.tmx-icon-plus:before {
  content: '\e905';
  color: #aeaebd;
}
.tmx-icon-plus-circled:before {
  content: '\e906';
  color: #aeaebd;
}
.tmx-icon-minus-circled:before {
  content: '\e939';
  color: #aeaebd;
}
.tmx-icon-radio:before {
  content: '\e907';
}
.tmx-icon-retry:before {
  content: '\e908';
  color: #aeaebd;
}

.tmx-icon-refresh:before {
  content: '\eb8b';
  color: #aeaebd;
}

.tmx-icon-radio-center:before {
  content: '\e909';
}
.tmx-icon-close:before {
  content: '\e90a';
}
.tmx-icon-chevron:before {
  content: '\e90b';
}
.tmx-icon-radio-filled:before {
  content: '\e90c';
}
.tmx-icon-search:before {
  content: '\e910';
}
.tmx-icon-eye-slash-solid:before {
  content: '\e92b';
}
.tmx-icon-link-solid:before {
  content: '\e92a';
}
.tmx-icon-arrow-alt-from-bottom:before {
  content: '\e92e';
}
.tmx-icon-user-plus:before {
  content: '\e92f';
}
.tmx-icon-clock-alt:before {
  content: '\e930';
}
.tmx-icon-clock:before {
  content: '\e931';
}
.tmx-icon-verified:before {
  content: '\e932';
}
.tmx-icon-follow-star:before {
  content: '\e933';
}
.tmx-icon-discover:before {
  content: '\e934';
}
.tmx-icon-visa:before {
  content: '\e935';
}
.tmx-icon-mastercard:before {
  content: '\e936';
}
.tmx-icon-amex:before {
  content: '\e937';
}
.tmx-icon-stripe:before {
  content: '\e938';
  color: #929292;
}
.tmx-icon-minus-circle:before {
  content: '\e939';
}
.tmx-icon-purchased:before {
  content: '\e93a';
}
.tmx-icon-paper:before {
  content: '\e93b';
  color: #333363;
}
.tmx-icon-cloud:before {
  content: '\e93c';
  color: #333363;
}
.tmx-icon-stop-watch:before {
  content: '\e93d';
  color: #333363;
}
.tmx-icon-tags:before {
  content: '\e93e';
  color: #333363;
}
.tmx-icon-envelope:before {
  content: '\e93f';
}
.tmx-icon-minus:before {
  content: '\e940';
}
.tmx-icon-phone:before {
  content: '\e941';
}
.tmx-icon-map-marked:before {
  content: '\e942';
}
.tmx-icon-sitemap:before {
  content: '\e943';
}
.tmx-icon-cloud-download:before {
  content: '\e944';
  color: #594ffe;
}
.tmx-icon-cloud-download_small:before {
  content: '\e945';
  color: #594ffe;
}
.tmx-icon-coffee-pot_big:before {
  content: '\e94f';
}
.tmx-icon-cloud-upload_small:before {
  content: '\e947';
  color: #594ffe;
}
.tmx-icon-fire-alt_big:before {
  content: '\e948';
}
.tmx-icon-cloud-upload_big:before {
  content: '\e949';
}
.tmx-icon-user-check_small:before {
  content: '\e94a';
  color: #594ffe;
}
.tmx-icon-user-check_big:before {
  content: '\e94b';
}
.tmx-icon-fire-alt_small:before {
  content: '\e94c';
  color: #594ffe;
}
.tmx-icon-coffee-pot_small:before {
  content: '\e94d';
  color: #594ffe;
}
.tmx-icon-send:before {
  content: '\eb53';
}
.tmx-icon-referral:before {
  content: '\e950';
}
.tmx-icon-dont-care {
  .path1:before {
    content: '\e957';
    color: rgb(89, 79, 254);
  }
  .path2:before {
    content: '\e958';
    margin-left: -1em;
    color: #f0f0f0;
  }
}
.tmx-icon-edit-org:before {
  content: '\e946';
}
.tmx-icon-Settings:before {
  content: '\e951';
}
.tmx-icon-sort-default:before {
  content: '\e954';
  color: #b5b5b5;
}
.tmx-icon-sort-up {
  .path1:before {
    content: '\e952';
    color: rgb(34, 34, 34);
  }
  .path2:before {
    content: '\e953';
    margin-left: -1em;
    color: rgb(181, 181, 181);
  }
}
.tmx-icon-sort-down {
  .path1:before {
    content: '\e955';
    color: rgb(181, 181, 181);
  }
  .path2:before {
    content: '\e956';
    margin-left: -1em;
    color: rgb(34, 34, 34);
  }
}
.tmx-icon-tmx-brand-mark-beta {
  .path1:before {
    content: '\e95b';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e965';
    margin-left: -3.349609375em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e966';
    margin-left: -3.349609375em;
    color: rgb(89, 79, 254);
  }
  .path4:before {
    content: '\e967';
    margin-left: -3.349609375em;
    color: rgb(181, 181, 181);
  }
}
.tmx-icon-tmx-brand-mark-beta-centered {
  .path1:before {
    content: '\e95c';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e95d';
    margin-left: -1.111328125em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e95e';
    margin-left: -1.111328125em;
    color: rgb(89, 79, 254);
  }
}
.tmx-icon-tmx-brand-logo-beta {
  .path1:before {
    content: '\e95f';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e960';
    margin-left: -5.849609375em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e961';
    margin-left: -5.849609375em;
    color: rgb(89, 79, 254);
  }
  .path4:before {
    content: '\e962';
    margin-left: -5.849609375em;
    color: rgb(45, 45, 79);
  }
  .path5:before {
    content: '\e959';
    margin-left: -5.849609375em;
    color: rgb(181, 181, 181);
  }
}
.tmx-icon-org-icon:before {
  content: '\e963';
  color: #929292;
}
.tmx-icon-user-icon:before {
  content: '\e964';
}

.tmx-icon-newmaker-step-two {
  .path1:before {
    content: '\e968';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e969';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e96a';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e96b';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
}

.tmx-icon-newmaker-step-three {
  .path1:before {
    content: '\e96c';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e96d';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path3:before {
    content: '\e96e';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e96f';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-newmaker-step-four {
  .path1:before {
    content: '\e970';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e971';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e972';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e973';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path5:before {
    content: '\e974';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-newmaker-step-one {
  .path1:before {
    content: '\e975';
    color: rgb(80, 226, 194);
  }
  .path2:before {
    content: '\e976';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e977';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e978';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path5:before {
    content: '\e979';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path6:before {
    content: '\e97a';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path7:before {
    content: '\e97b';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path8:before {
    content: '\e97c';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-media-step-three {
  .path1:before {
    content: '\e97d';
    color: rgb(215, 213, 255);
  }
  .path2:before {
    content: '\e97e';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path3:before {
    content: '\e97f';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e980';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-media-step-four {
  .path1:before {
    content: '\e981';
    color: rgb(215, 213, 255);
  }
  .path2:before {
    content: '\e982';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e983';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e984';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
}

.tmx-icon-media-step-one {
  .path1:before {
    content: '\e985';
    color: rgb(215, 213, 255);
  }
  .path2:before {
    content: '\e986';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e987';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e988';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path5:before {
    content: '\e989';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path6:before {
    content: '\e98a';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path7:before {
    content: '\e98b';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path8:before {
    content: '\e98c';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-media-step-two {
  .path1:before {
    content: '\e98d';
    color: rgb(215, 213, 255);
  }
  .path2:before {
    content: '\e98e';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path3:before {
    content: '\e98f';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path4:before {
    content: '\e990';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path5:before {
    content: '\e991';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path6:before {
    content: '\e992';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path7:before {
    content: '\e993';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path8:before {
    content: '\e994';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}
.tmx-icon-linkedin {
  .path1:before {
    content: '\e995';
    color: rgb(45, 45, 79);
  }
  .path2:before {
    content: '\e996';
    margin-left: -1em;
    color: rgb(150, 150, 167);
  }
}
.tmx-icon-instagram {
  .path1:before {
    content: '\e997';
    color: rgb(45, 45, 79);
  }
  .path2:before {
    content: '\e998';
    margin-left: -1em;
    color: rgb(150, 150, 167);
  }
  .path3:before {
    content: '\e999';
    margin-left: -1em;
    color: rgb(150, 150, 167);
  }
}

// We are not using this icon yet due to the fact
// that the display is not detailed so we are using the
// png format for now
.tmx-icon-home-page-banner {
  .path1:before {
    content: '\e99a';
    color: rgb(224, 224, 224);
  }
  .path2:before {
    content: '\e99b';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path3:before {
    content: '\e99c';
    margin-left: -1.4658203125em;
    color: rgb(236, 236, 236);
  }
  .path4:before {
    content: '\e99d';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path5:before {
    content: '\e99e';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path6:before {
    content: '\e99f';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path7:before {
    content: '\e9a0';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path8:before {
    content: '\e9a1';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path9:before {
    content: '\e9a2';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path10:before {
    content: '\e9a3';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path11:before {
    content: '\e9a4';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path12:before {
    content: '\e9a5';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path13:before {
    content: '\e9a6';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path14:before {
    content: '\e9a7';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path15:before {
    content: '\e9a8';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path16:before {
    content: '\e9a9';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path17:before {
    content: '\e9aa';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path18:before {
    content: '\e9ab';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path19:before {
    content: '\e9ac';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path20:before {
    content: '\e9ad';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path21:before {
    content: '\e9ae';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path22:before {
    content: '\e9af';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path23:before {
    content: '\e9b0';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path24:before {
    content: '\e9b1';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path25:before {
    content: '\e9b2';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path26:before {
    content: '\e9b3';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path27:before {
    content: '\e9b4';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path28:before {
    content: '\e9b5';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path29:before {
    content: '\e9b6';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path30:before {
    content: '\e9b7';
    margin-left: -1.4658203125em;
    color: rgb(204, 204, 204);
  }
  .path31:before {
    content: '\e9b8';
    margin-left: -1.4658203125em;
    color: rgb(204, 204, 204);
  }
  .path32:before {
    content: '\e9b9';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path33:before {
    content: '\e9ba';
    margin-left: -1.4658203125em;
    color: rgb(204, 204, 204);
  }
  .path34:before {
    content: '\e9bb';
    margin-left: -1.4658203125em;
    color: rgb(204, 204, 204);
  }
  .path35:before {
    content: '\e9bc';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path36:before {
    content: '\e9bd';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path37:before {
    content: '\e9be';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path38:before {
    content: '\e9bf';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path39:before {
    content: '\e9c0';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path40:before {
    content: '\e9c1';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path41:before {
    content: '\e9c2';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path42:before {
    content: '\e9c3';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path43:before {
    content: '\e9c4';
    margin-left: -1.4658203125em;
    color: rgb(0, 0, 0);
    opacity: 0.302;
  }
  .path44:before {
    content: '\e9c5';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path45:before {
    content: '\e9c6';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path46:before {
    content: '\e9c7';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path47:before {
    content: '\e9c8';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path48:before {
    content: '\e9c9';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path49:before {
    content: '\e9ca';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path50:before {
    content: '\e9cb';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path51:before {
    content: '\e9cc';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path52:before {
    content: '\e9cd';
    margin-left: -1.4658203125em;
    color: rgb(250, 250, 250);
  }
  .path53:before {
    content: '\e9ce';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path54:before {
    content: '\e9cf';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path55:before {
    content: '\e9d0';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path56:before {
    content: '\e9d1';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path57:before {
    content: '\e9d2';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path58:before {
    content: '\e9d3';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path59:before {
    content: '\e9d4';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path60:before {
    content: '\e9d5';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path61:before {
    content: '\e9d6';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path62:before {
    content: '\e9d7';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path63:before {
    content: '\e9d8';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path64:before {
    content: '\e9d9';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path65:before {
    content: '\e9da';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path66:before {
    content: '\e9db';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path67:before {
    content: '\e9dc';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path68:before {
    content: '\e9dd';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path69:before {
    content: '\e9de';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path70:before {
    content: '\e9df';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path71:before {
    content: '\e9e0';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path72:before {
    content: '\e9e1';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path73:before {
    content: '\e9e2';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path74:before {
    content: '\e9e3';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path75:before {
    content: '\e9e4';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path76:before {
    content: '\e9e5';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path77:before {
    content: '\e9e6';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path78:before {
    content: '\e9e7';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path79:before {
    content: '\e9e8';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path80:before {
    content: '\e9e9';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path81:before {
    content: '\e9ea';
    margin-left: -1.4658203125em;
    color: rgb(0, 0, 0);
    opacity: 0.302;
  }
  .path82:before {
    content: '\e9eb';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path83:before {
    content: '\e9ec';
    margin-left: -1.4658203125em;
    color: rgb(236, 236, 236);
  }
  .path84:before {
    content: '\e9ed';
    margin-left: -1.4658203125em;
    color: rgb(250, 250, 250);
  }
  .path85:before {
    content: '\e9ee';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path86:before {
    content: '\e9ef';
    margin-left: -1.4658203125em;
    color: rgb(0, 0, 0);
    opacity: 0.302;
  }
  .path87:before {
    content: '\e9f0';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path88:before {
    content: '\e9f1';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path89:before {
    content: '\e9f2';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path90:before {
    content: '\e9f3';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path91:before {
    content: '\e9f4';
    margin-left: -1.4658203125em;
    color: rgb(184, 105, 93);
  }
  .path92:before {
    content: '\e9f5';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path93:before {
    content: '\e9f6';
    margin-left: -1.4658203125em;
    color: rgb(184, 105, 93);
  }
  .path94:before {
    content: '\e9f7';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path95:before {
    content: '\e9f8';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path96:before {
    content: '\e9f9';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path97:before {
    content: '\e9fa';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path98:before {
    content: '\e9fb';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path99:before {
    content: '\e9fc';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path100:before {
    content: '\e9fd';
    margin-left: -1.4658203125em;
    color: rgb(147, 81, 73);
  }
  .path101:before {
    content: '\e9fe';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path102:before {
    content: '\e9ff';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path103:before {
    content: '\ea00';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path104:before {
    content: '\ea01';
    margin-left: -1.4658203125em;
    color: rgb(147, 81, 73);
  }
  .path105:before {
    content: '\ea02';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path106:before {
    content: '\ea03';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path107:before {
    content: '\ea04';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path108:before {
    content: '\ea05';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path109:before {
    content: '\ea06';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path110:before {
    content: '\ea07';
    margin-left: -1.4658203125em;
    color: rgb(184, 105, 93);
  }
  .path111:before {
    content: '\ea08';
    margin-left: -1.4658203125em;
    color: rgb(147, 81, 73);
  }
  .path112:before {
    content: '\ea09';
    margin-left: -1.4658203125em;
    color: rgb(147, 81, 73);
  }
  .path113:before {
    content: '\ea0a';
    margin-left: -1.4658203125em;
    color: rgb(184, 105, 93);
  }
  .path114:before {
    content: '\ea0b';
    margin-left: -1.4658203125em;
    color: rgb(250, 250, 250);
  }
  .path115:before {
    content: '\ea0c';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path116:before {
    content: '\ea0d';
    margin-left: -1.4658203125em;
    color: rgb(179, 179, 179);
  }
  .path117:before {
    content: '\ea0e';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path118:before {
    content: '\ea0f';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path119:before {
    content: '\ea10';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path120:before {
    content: '\ea11';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path121:before {
    content: '\ea12';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path122:before {
    content: '\ea13';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path123:before {
    content: '\ea14';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path124:before {
    content: '\ea15';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path125:before {
    content: '\ea16';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path126:before {
    content: '\ea17';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path127:before {
    content: '\ea18';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path128:before {
    content: '\ea19';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path129:before {
    content: '\ea1a';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path130:before {
    content: '\ea1b';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path131:before {
    content: '\ea1c';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path132:before {
    content: '\ea1d';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path133:before {
    content: '\ea1e';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path134:before {
    content: '\ea1f';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path135:before {
    content: '\ea20';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path136:before {
    content: '\ea21';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path137:before {
    content: '\ea22';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path138:before {
    content: '\ea23';
    margin-left: -1.4658203125em;
    color: rgb(0, 0, 0);
    opacity: 0.302;
  }
  .path139:before {
    content: '\ea24';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path140:before {
    content: '\ea25';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path141:before {
    content: '\ea26';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path142:before {
    content: '\ea27';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path143:before {
    content: '\ea28';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path144:before {
    content: '\ea29';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path145:before {
    content: '\ea2a';
    margin-left: -1.4658203125em;
    color: rgb(255, 154, 108);
  }
  .path146:before {
    content: '\ea2b';
    margin-left: -1.4658203125em;
    color: rgb(255, 191, 156);
  }
  .path147:before {
    content: '\ea2c';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path148:before {
    content: '\ea2d';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path149:before {
    content: '\ea2e';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path150:before {
    content: '\ea2f';
    margin-left: -1.4658203125em;
    color: rgb(32, 45, 52);
  }
  .path151:before {
    content: '\ea30';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path152:before {
    content: '\ea31';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path153:before {
    content: '\ea32';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path154:before {
    content: '\ea33';
    margin-left: -1.4658203125em;
    color: rgb(153, 153, 153);
  }
  .path155:before {
    content: '\ea34';
    margin-left: -1.4658203125em;
    color: rgb(45, 45, 79);
  }
  .path156:before {
    content: '\ea35';
    margin-left: -1.4658203125em;
    color: rgb(45, 45, 79);
  }
  .path157:before {
    content: '\ea36';
    margin-left: -1.4658203125em;
    color: rgb(153, 153, 153);
  }
  .path158:before {
    content: '\ea37';
    margin-left: -1.4658203125em;
    color: rgb(153, 153, 153);
  }
  .path159:before {
    content: '\ea38';
    margin-left: -1.4658203125em;
    color: rgb(153, 153, 153);
  }
  .path160:before {
    content: '\ea39';
    margin-left: -1.4658203125em;
    color: rgb(255, 255, 255);
  }
  .path161:before {
    content: '\ea3a';
    margin-left: -1.4658203125em;
    color: rgb(80, 226, 194);
  }
  .path162:before {
    content: '\ea3b';
    margin-left: -1.4658203125em;
    color: rgb(45, 45, 79);
  }
  .path163:before {
    content: '\ea3c';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path164:before {
    content: '\ea3d';
    margin-left: -1.4658203125em;
    color: rgb(89, 79, 254);
  }
  .path165:before {
    content: '\ea3e';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path166:before {
    content: '\ea3f';
    margin-left: -1.4658203125em;
    color: rgb(236, 236, 236);
  }
  .path167:before {
    content: '\ea40';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
  .path168:before {
    content: '\ea41';
    margin-left: -1.4658203125em;
    color: rgb(236, 236, 236);
  }
  .path169:before {
    content: '\ea42';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path170:before {
    content: '\ea43';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path171:before {
    content: '\ea44';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path172:before {
    content: '\ea45';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path173:before {
    content: '\ea46';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path174:before {
    content: '\ea47';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path175:before {
    content: '\ea48';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path176:before {
    content: '\ea49';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path177:before {
    content: '\ea4a';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path178:before {
    content: '\ea4b';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path179:before {
    content: '\ea4c';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path180:before {
    content: '\ea4d';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path181:before {
    content: '\ea4e';
    margin-left: -1.4658203125em;
    color: rgb(224, 224, 224);
  }
  .path182:before {
    content: '\ea4f';
    margin-left: -1.4658203125em;
    color: rgb(245, 245, 245);
  }
}

.tmx-icon-home-page-media {
  .path1:before {
    content: '\ea50';
    color: rgb(38, 38, 188);
    opacity: 0.102;
  }
  .path2:before {
    content: '\ea51';
    margin-left: -1.46875em;
    color: rgb(38, 38, 188);
    opacity: 0.102;
  }
  .path3:before {
    content: '\ea52';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\ea53';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path5:before {
    content: '\ea54';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path6:before {
    content: '\ea55';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path7:before {
    content: '\ea56';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path8:before {
    content: '\ea57';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path9:before {
    content: '\ea58';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path10:before {
    content: '\ea59';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path11:before {
    content: '\ea5a';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path12:before {
    content: '\ea5b';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path13:before {
    content: '\ea5c';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path14:before {
    content: '\ea5d';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path15:before {
    content: '\ea5e';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path16:before {
    content: '\ea5f';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path17:before {
    content: '\ea60';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path18:before {
    content: '\ea61';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path19:before {
    content: '\ea62';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path20:before {
    content: '\ea63';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path21:before {
    content: '\ea64';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path22:before {
    content: '\ea65';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path23:before {
    content: '\ea66';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path24:before {
    content: '\ea67';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path25:before {
    content: '\ea68';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path26:before {
    content: '\ea69';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path27:before {
    content: '\ea6a';
    margin-left: -1.46875em;
    color: rgb(255, 255, 255);
  }
  .path28:before {
    content: '\ea6b';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path29:before {
    content: '\ea6c';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path30:before {
    content: '\ea6d';
    margin-left: -1.46875em;
    color: rgb(80, 226, 194);
  }
  .path31:before {
    content: '\ea6e';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path32:before {
    content: '\ea6f';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path33:before {
    content: '\ea70';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
  .path34:before {
    content: '\ea71';
    margin-left: -1.46875em;
    color: rgb(89, 79, 254);
  }
}

.tmx-icon-home-page-newsmaker {
  .path1:before {
    content: '\ea72';
    color: rgb(0, 0, 0);
  }
  .path2:before {
    content: '\ea73';
    margin-left: -0.953125em;
    color: rgb(38, 38, 188);
    opacity: 0.0118;
  }
  .path3:before {
    content: '\ea74';
    margin-left: -0.953125em;
    color: rgb(38, 38, 188);
    opacity: 0.102;
  }
  .path4:before {
    content: '\ea75';
    margin-left: -0.953125em;
    color: rgb(38, 38, 188);
    opacity: 0.102;
  }
  .path5:before {
    content: '\ea76';
    margin-left: -0.953125em;
    color: rgb(38, 38, 188);
    opacity: 0.102;
  }
  .path6:before {
    content: '\ea77';
    margin-left: -0.953125em;
    color: rgb(255, 255, 255);
  }
  .path7:before {
    content: '\ea78';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path8:before {
    content: '\ea79';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path9:before {
    content: '\ea7a';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path10:before {
    content: '\ea7b';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path11:before {
    content: '\ea7c';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path12:before {
    content: '\ea7d';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path13:before {
    content: '\ea7e';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path14:before {
    content: '\ea7f';
    margin-left: -0.953125em;
    color: rgb(255, 255, 255);
  }
  .path15:before {
    content: '\ea80';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path16:before {
    content: '\ea81';
    margin-left: -0.953125em;
    color: rgb(101, 131, 254);
  }
  .path17:before {
    content: '\ea82';
    margin-left: -0.953125em;
    color: rgb(255, 255, 255);
  }
  .path18:before {
    content: '\ea83';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path19:before {
    content: '\ea84';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path20:before {
    content: '\ea85';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path21:before {
    content: '\ea86';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path22:before {
    content: '\ea87';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path23:before {
    content: '\ea88';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path24:before {
    content: '\ea89';
    margin-left: -0.953125em;
    color: rgb(80, 226, 194);
  }
  .path25:before {
    content: '\ea8a';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path26:before {
    content: '\ea8b';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path27:before {
    content: '\ea8c';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path28:before {
    content: '\ea8d';
    margin-left: -0.953125em;
    color: rgb(255, 255, 255);
  }
  .path29:before {
    content: '\ea8e';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path30:before {
    content: '\ea8f';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path31:before {
    content: '\ea90';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path32:before {
    content: '\ea91';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path33:before {
    content: '\ea92';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
  .path34:before {
    content: '\ea93';
    margin-left: -0.953125em;
    color: rgb(89, 79, 254);
  }
}
.tmx-icon-marketing-arrow:before {
  content: '\ea94';
}

.tmx-icon-nbcuniversal:before {
  content: '\ea95';
  color: #6c6c84;
}

.tmx-icon-meredith:before {
  content: '\eaa6';
  color: #6c6c84;
}

.tmx-icon-fox-news:before {
  content: '\eaa7';
  color: #6c6c84;
}
.tmx-icon-4nbc:before {
  content: '\eaa8';
  color: #6c6c84;
}

.tmx-icon-telemundo {
  .path1:before {
    content: '\ea96';
    color: rgb(93, 93, 113);
  }
  .path2:before {
    content: '\ea97';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path3:before {
    content: '\ea98';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path4:before {
    content: '\ea99';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path5:before {
    content: '\ea9a';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path6:before {
    content: '\ea9b';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path7:before {
    content: '\ea9c';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path8:before {
    content: '\ea9d';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path9:before {
    content: '\ea9e';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path10:before {
    content: '\ea9f';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path11:before {
    content: '\eaa0';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path12:before {
    content: '\eaa1';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path13:before {
    content: '\eaa2';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path14:before {
    content: '\eaa3';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
  .path15:before {
    content: '\eaa4';
    margin-left: -1.185546875em;
    color: rgb(99, 7, 7);
  }
  .path16:before {
    content: '\eaa5';
    margin-left: -1.185546875em;
    color: rgb(108, 108, 132);
  }
}

.tmx-icon-warner-media {
  .path1:before {
    content: '\eaa9';
    color: rgb(93, 93, 113);
  }
  .path2:before {
    content: '\eaaa';
    margin-left: -9.5810546875em;
    color: rgb(108, 108, 132);
  }
  .path3:before {
    content: '\eaab';
    margin-left: -9.5810546875em;
    color: rgb(93, 93, 113);
  }
  .path4:before {
    content: '\eaac';
    margin-left: -9.5810546875em;
    color: rgb(93, 93, 113);
  }
  .path5:before {
    content: '\eaad';
    margin-left: -9.5810546875em;
    color: rgb(108, 108, 132);
  }
  .path6:before {
    content: '\eaae';
    margin-left: -9.5810546875em;
    color: rgb(93, 93, 113);
  }
  .path7:before {
    content: '\eaaf';
    margin-left: -9.5810546875em;
    color: rgb(108, 108, 132);
  }
  .path8:before {
    content: '\eab0';
    margin-left: -9.5810546875em;
    color: rgb(93, 93, 113);
  }
  .path9:before {
    content: '\eab1';
    margin-left: -9.5810546875em;
    color: rgb(108, 108, 132);
  }
  .path10:before {
    content: '\eab2';
    margin-left: -9.5810546875em;
    color: rgb(93, 93, 113);
  }
  .path11:before {
    content: '\eab3';
    margin-left: -9.5810546875em;
    color: rgb(108, 108, 132);
  }
}
.tmx-icon-the-weather-company:before {
  content: '\eab4';
  color: #6c6c84;
}

.tmx-icon-tegna:before {
  content: '\eab5';
  color: #6c6c84;
}

.tmx-icon-why-tmx-one {
  .path1:before {
    content: '\eab6';
    color: rgb(89, 79, 254);
  }
  .path2:before {
    content: '\eab7';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path3:before {
    content: '\eab8';
    margin-left: -1em;
    color: rgb(89, 79, 254);
  }
  .path4:before {
    content: '\eab9';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
  .path5:before {
    content: '\eaba';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
  .path6:before {
    content: '\eabb';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
  .path7:before {
    content: '\eabc';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
  .path8:before {
    content: '\eabd';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
  .path9:before {
    content: '\eabe';
    margin-left: -1em;
    color: rgb(169, 234, 255);
  }
}

.tmx-icon-why-tmx-two {
  .path1:before {
    content: '\eabf';
    color: rgb(89, 79, 254);
  }
  .path2:before {
    content: '\eac0';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path3:before {
    content: '\eac1';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path4:before {
    content: '\eac2';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path5:before {
    content: '\eac3';
    margin-left: -1em;
    color: rgb(89, 79, 254);
  }
}

.tmx-icon-why-tmx-three {
  .path1:before {
    content: '\eac4';
    color: rgb(89, 79, 254);
  }
  .path2:before {
    content: '\eac5';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path3:before {
    content: '\eac6';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path4:before {
    content: '\eac7';
    margin-left: -1em;
    color: rgb(89, 79, 254);
  }
}

.tmx-icon-why-tmx-four {
  .path1:before {
    content: '\eac8';
    color: rgb(89, 79, 254);
  }
  .path2:before {
    content: '\eac9';
    margin-left: -1em;
    color: rgb(255, 245, 155);
  }
  .path3:before {
    content: '\eaca';
    margin-left: -1em;
    color: rgb(89, 79, 254);
  }
  .path4:before {
    content: '\eacb';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path5:before {
    content: '\eacc';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path6:before {
    content: '\eacd';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path7:before {
    content: '\eace';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
}

.tmx-icon-twitter {
  .path1:before {
    content: '\ead5';
    color: rgb(45, 45, 79);
  }
  .path2:before {
    content: '\ead6';
    margin-left: -1em;
    color: rgb(150, 150, 167);
  }
}

.tmx-icon-facebook {
  .path1:before {
    content: '\ead7';
    color: rgb(45, 45, 79);
  }
  .path2:before {
    content: '\ead8';
    margin-left: -1em;
    color: rgb(150, 150, 167);
  }
}

.tmx-icon-our-values-three {
  .path1:before {
    content: '\ead9';
    color: rgb(213, 213, 213);
  }

  .path2:before {
    content: '\eada';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }

  .path3:before {
    content: '\eadb';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}

.tmx-icon-our-values-four {
  .path1:before {
    content: '\eadc';
    color: rgb(213, 213, 213);
  }
  .path2:before {
    content: '\eadd';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path3:before {
    content: '\eade';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path4:before {
    content: '\eadf';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path5:before {
    content: '\eae0';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}

.tmx-icon-our-values-two {
  .path1:before {
    content: '\eae1';
    color: rgb(213, 213, 213);
  }
  .path2:before {
    content: '\eae2';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path3:before {
    content: '\eae3';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path4:before {
    content: '\eae4';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}

.tmx-icon-our-values-one {
  .path1:before {
    content: '\eae5';
    color: rgb(213, 213, 213);
  }
  .path2:before {
    content: '\eae6';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .path3:before {
    content: '\eae7';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}

.tmx-icon-publicist-one {
  .path1:before {
    content: '\eae8';
    color: rgb(52, 52, 92);
  }
  .path2:before {
    content: '\eae9';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path3:before {
    content: '\eaea';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\eaeb';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path5:before {
    content: '\eaec';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-publicist-four {
  .path1:before {
    content: '\eaed';
    color: rgb(52, 52, 92);
  }
  .path2:before {
    content: '\eaee';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path3:before {
    content: '\eaef';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\eaf0';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path5:before {
    content: '\eaf1';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path6:before {
    content: '\eaf2';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
  .path7:before {
    content: '\eaf3';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-publicist-two {
  .path1:before {
    content: '\eaf4';
    color: rgb(52, 52, 92);
  }
  .path2:before {
    content: '\eaf5';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path3:before {
    content: '\eaf6';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\eaf7';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
  .path5:before {
    content: '\eaf8';
    margin-left: -1em;
    color: rgb(45, 45, 79);
  }
}

.tmx-icon-publicist-three {
  .path1:before {
    content: '\eaf9';
    color: rgb(52, 52, 92);
  }
  .path2:before {
    content: '\eafa';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path3:before {
    content: '\eafb';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\eafc';
    margin-left: -1em;
    color: rgb(80, 226, 194);
  }
}

.tmx-icon-combined-shape:before {
  content: '\eb02';
}
.tmx-icon-delete:before {
  content: '\eb04';
}
.tmx-icon-follow-organization:before {
  content: '\eb05';
}

.tmx-icon-twitter {
  .path1:before {
    content: '\eaff';
    color: rgb(44, 169, 224);
  }
  .path2:before {
    content: '\eb00';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-icon-ellipsis:before {
  content: '\eafe';
  color: #fff;
}

.tmx-icon-radar:before {
  content: '\eafd';
}

.tmx-icon-edit-organization:before {
  content: '\eb07';
}
.tmx-icon-membership:before {
  content: '\eb08';
}
.tmx-icon-trash1:before {
  content: '\eb09';
}
.tmx-icon-content:before {
  content: '\eb0a';
}
.tmx-icon-lock-open:before {
  content: '\eb0b';
}
.tmx-icon-lock:before {
  content: '\eb0c';
}
.tmx-icon-user-blk:before {
  content: '\eb06';
}

.tmx-icon-tv:before {
  content: '\eb0d';
}
.tmx-icon-arrow-right:before {
  content: '\eb0e';
}
.tmx-icon-check-circle:before {
  content: '\eb0f';
}
.tmx-icon-file-alt:before {
  content: '\eb10';
}
.tmx-icon-top-nav-upload:before {
  content: '\eb11';
}
.tmx-icon-upgrade:before {
  content: '\eb12';
}

.tmx-icon-radio-fill:before {
  content: '\eb13';
}

.tmx-icon-title-shared:before {
  content: '\eb14';
}

.tmx-icon-title-license:before {
  content: '\eb15';
}

.tmx-icon-cnn:before {
  content: '\eb16';
  color: #6c6c84;
}

.tmx-icon-stories:before {
  content: '\eb17';
}
.tmx-icon-contacts:before {
  content: '\eb18';
}

.tmx-icon-ugc:before {
  content: '\eb1f';
}

.tmx-icon-youtube {
  .path1:before {
    content: '\eb20';
    color: rgb(240, 240, 240);
  }
  .path2:before {
    content: '\eb21';
    margin-left: -1em;
    color: rgb(217, 37, 42);
  }
  .path3:before {
    content: '\eb22';
    margin-left: -1em;
    color: rgb(255, 255, 254);
  }
}

.tmx-icon-tiktok {
  .path1:before {
    content: '\eb19';
    color: rgb(0, 0, 0);
  }
  .path2:before {
    content: '\eb1a';
    margin-left: -1em;
    color: rgb(37, 244, 238);
  }
  .path3:before {
    content: '\eb1b';
    margin-left: -1em;
    color: rgb(37, 244, 238);
  }
  .path4:before {
    content: '\eb1c';
    margin-left: -1em;
    color: rgb(254, 44, 85);
  }
  .path5:before {
    content: '\eb1d';
    margin-left: -1em;
    color: rgb(254, 44, 85);
  }
  .path6:before {
    content: '\eb1e';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.tmx-icon-email-alerts:before {
  content: '\eb23';
}

.tmx-icon-reddit_circle {
  .path1:before {
    content: '\eb24';
    color: rgb(255, 69, 0);
  }
  .path2:before {
    content: '\eb25';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.tmx-icon-facebook_circle:before {
  content: '\eb26';
  // color: #3a589b;
}

.tmx-icon-instagram_circle {
  .path1:before {
    content: '\eb27';
    color: rgb(0, 0, 0);
  }
  .path2:before {
    content: '\eb28';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path3:before {
    content: '\eb29';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .path4:before {
    content: '\eb2a';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.tmx-icon-marketing-arrow:before {
  content: '\ea94';
}

.tmx-icon-tray-discover:before {
  content: '\eb56';
}
.tmx-icon-linked-blk:before {
  content: '\eb5b';
  color: #b5b5b5;
}
.tmx-icon-link-solid:before {
  content: '\e92a';
}
.tmx-icon-linked-blk:before {
  content: '\eb5b';
  color: #b5b5b5a3;
}
.tmx-icon-camera-1:before {
  content: '\ebae';
  color: #b5b5b5;
}
.tmx-icon-camera-2:before {
  content: '\ebaf';
  color: #b5b5b5;
}
