@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-homePage {
  &__logoWrapper {
    text-align: center;
    padding-top: 40px;
  }

  &__signinWrapper {
    padding-top: 117px;
  }
}
