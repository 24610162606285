@import '../../../styles/variables/colors';
@import '../../../styles/mixins/mediaqueries';
@import '../../../styles/variables/sizes';

.tmx-sliderContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 $black-card;
  min-height: 260px;
  max-height: 260px;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;

  display: inline-block;
  background: -moz-linear-gradient(
    top,
    $steel-gray-zero-opacity,
    $steel-gray-with-opacity
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop($steel-gray-with-opacity),
    color-stop($steel-gray-zero-opacity)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $steel-gray-zero-opacity,
    $steel-gray-with-opacity
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    $steel-gray-zero-opacity,
    $steel-gray-with-opacity
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    $steel-gray-zero-opacity,
    $steel-gray-with-opacity
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    $steel-gray-zero-opacity,
    $steel-gray-with-opacity
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

  @include tablet {
    min-height: 400px;
    max-height: 400px;
  }

  @include desktop {
    min-height: 400px;
    max-height: 400px;
  }

  &--loading {
    animation: fade 2s infinite ease-in-out;
    background: $ghost-white-opacity;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4% 2%;

    @include tablet {
      padding: 2% 1%;
    }
  }

  &__image {
    min-height: 260px;
    max-height: 260px;

    min-width: 100%;
    max-width: 100%;

    object-fit: cover;
    margin: auto;

    z-index: -1;
    position: relative;

    @include tablet {
      min-height: 400px;
      max-height: 400px;
    }

    @include desktop {
      min-height: 400px;
      max-height: 400px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: $font-size-15;
    line-height: normal;
    margin: 0;
    text-align: left;

    word-break: break-word;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 4px 8px $black-card;

    @include tablet {
      font-size: $font-size-22;
      font-weight: 500;
      line-height: normal;
    }

    @include small-tablet {
      -webkit-line-clamp: 2;
    }
  }

  &__profileContent {
    position: absolute;
    text-align: center;
    padding: 16px;
    vertical-align: bottom;
    align-self: end;
    bottom: 46%;
    width: 73%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 39px;
    min-height: 39px;
    color: $white;
    padding-left: 3%;

    @include tablet {
      bottom: 28%;
    }
  }

  &__titleContent {
    position: absolute;
    text-align: center;
    padding: 16px;
    vertical-align: bottom;
    align-self: end;
    padding-left: 5%;
    height: 70px;

    bottom: 22%;
    width: 272px;

    @include small-tablet {
      bottom: 13%;
      width: 534px;
    }
  }

  &__clearedTitle {
    color: $malachite;
  }

  &__profilePic {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin-right: 9px;
    background-color: $white;
    display: contents;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &.newsFeed {
      cursor: initial;
    }
  }

  &__profileInitial {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: $font-size-12;
    color: $ghost-white;

    &--onlyText {
      width: 28px;
      height: 28px !important;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      background-color: $dark-grey;
    }

    &--loading {
      width: 28px;
      height: 28px !important;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      background-color: $athens-gray;
    }
  }

  &__companyName {
    font-size: $font-size-12;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-transform: uppercase;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    overflow: hidden;
    z-index: 11;
    outline: none;
    margin-right: 7px;
    margin-left: 5%;

    @include tablet {
      margin-left: 1%;
    }
  }

  &__actionsWrapper {
    flex: 0 0 80%;
    display: flex;
    align-items: inherit;
    padding: 2px 0 0 6px;
    margin-right: 2px;
    position: relative;

    .tmx-button {
      color: $white;
      font-size: $font-size-18;
    }

    @include tablet {
      padding: 2px 0 0 3%;
    }
  }

  &__locationContent {
    display: flex;
    justify-content: space-between;
    position: absolute;
    font-size: $font-size-12;
    padding: 16px;
    padding-left: 5%;
    width: 100%;
    top: 82%;

    @include tablet {
      top: 87%;
    }
  }

  &__locationContainer {
    display: flex;
    align-items: center;
  }

  &__location {
    font-size: $font-size-12;
    line-height: normal;
    font-weight: normal;
    padding-left: 10px;
    color: $shades-of-whisper;
    overflow: hidden;
    text-overflow: ellipsis;
    visibility: visible;

    &.hide {
      visibility: hidden;
    }
  }

  &__bannerType {
    color: $shades-of-whisper;
  }

  &__noLocation {
    visibility: hidden;
    padding: 8px;
  }

  &__locationIcon {
    color: $shades-of-whisper;
  }

  &__linkItem {
    display: contents;
  }

  &__dummyTitle1 {
    width: 100%;
    height: 17px;
    background-color: $athens-gray;
    border-radius: 2px;
    margin-top: 2%;
  }

  &__dummyTitle2 {
    width: 86%;
    height: 17px;
    background-color: $athens-gray;
    border-radius: 2px;
    margin-top: 3%;
  }

  &__dummyName {
    width: 20%;
    height: 11px;
    background-color: $athens-gray;
    border-radius: 2px;
    margin-left: 2%;
  }
}
