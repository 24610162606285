@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-tools {
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 160px 30px 0;

  @include tablet {
    padding: 110px 88px 0;
  }

  @include desktop {
    padding: 130px 11% 0;
  }

  &__uploaders {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include desktop {
      justify-content: space-between;
      margin-bottom: 58px;
    }
  }

  &__uploader {
    background-color: $white;
    padding: 40px 30px;
    width: 400px;
    height: 279px;
    text-align: left;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 $black-card;

    &.removeSpace {
      margin-right: 0;
    }

    @include tablet {
      width: 470px;
      padding: 40px 35px;
    }

    @include desktop {
      width: 49%;
      margin-bottom: 20px;
      padding: 40px 35px;

      &.carousel {
        width: 49%;
      }
    }
  }

  &__title {
    font-size: $font-size-24;
    margin: 20px 0 20px;
    color: $granite-grey99;

    &--titleAlig {
      margin-top: 10px;
    }
  }

  &__subtitle {
    color: $spun-pearl;
    font-size: $font-size-15;
    margin-bottom: 16px;
    line-height: 1.53;

    @include tablet {
      margin-bottom: 26px;
    }
  }

  &__btn {
    font-size: $font-size-15;
    font-weight: normal;
    margin-bottom: 20px;

    &.arrowBtn {
      display: flex;
      align-items: center;
      color: $lucky-point;
      flex-direction: row-reverse;
    }
  }

  &__icon {
    color: $gray59;

    &--marginChange {
      margin-left: -10px;
    }
  }

  &__buttonIcon {
    color: $gray59;
    margin-left: 10px;
  }
}
