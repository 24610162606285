@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-fileUpload {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: $athens-gray;
  width: 100%;
  min-height: 120px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 22px;

  @include desktop {
    width: 400px;
  }

  &__uploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-18;
    line-height: normal;
    flex: 1 1 100%;
    outline: none;
    max-width: 251px;
    color: $nero;

    span {
      text-decoration: underline;
      margin-right: 7px;
      color: $majorelle-blue;
    }

    @include desktop {
      display: none;
    }
  }

  &__fileList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    padding: 0;
  }

  &__file {
    display: flex;
    padding: 10px;
    width: 100%;

    @include tablet {
      width: 100%;
    }

    @include desktop {
      width: 100%;
    }
  }

  &__fileName {
    max-width: 80%;
    margin: 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__fileDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;

    @include desktop {
      width: 100%;
    }
  }

  &__fileDelete {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .icon {
      color: $maximum-blue-purple;
      border-radius: 50%;
      padding: 6px;
      border: 1px solid $maximum-blue-purple;
      margin-left: 8px;
    }
  }

  &__nameAndStatus {
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    @include desktop {
      width: 100%;
    }
  }

  &__complete {
    color: $turquoise;
  }

  &__uploading {
    color: $spanish-grey;
  }

  &__failed {
    color: $brink-pink;
  }

  &__attachWrapper {
    display: flex;
    align-items: center;
  }

  &__iconAttach {
    font-size: 2.5rem;
  }
  &__mediaAttach {
    font-size: 2rem;
    margin-right: 5px;
  }

  &__uploadText {
    padding-left: 10px;
    
    @include desktop {
      display: block;
      font-size: $font-size-18;
      max-width: 348px;
      line-height: $font-size-41;
      text-align: center;
      font-weight: 500;
      color: $dark-grey;

      span {
        color: $majorelle-blue;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }

  &__browseText {
    display: block;
    font-size: $font-size-16;
    line-height: $font-size-21;
    text-align: center;
    font-weight: 300;
    padding-top: 15px;
    margin: 0;

    span {
      color: $majorelle-blue;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
    }
  }

  &__dropzone {
    position: absolute;
    width: 400px;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  &__dropzoneBlock {
    display: none;
  }
}
