// Fonts
$font-size-4: 0.25rem;
$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-13: 0.813rem;
$font-size-14: 0.875rem;
$font-size-15: 0.9375rem;
$font-size-16: 1rem;
$font-size-17: 1.063rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-21: 1.3125rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-32: 2rem;
$font-size-35: 2.1875rem;
$font-size-36: 2.25rem;
$font-size-41: 2.5625rem;
$font-size-48: 3rem;
$font-size-50: 3.125rem;
$font-size-56: 3.5rem;
$font-size-60: 3.75rem;
$font-size-80: 5.06rem;
$font-size-86: 5.375rem;
