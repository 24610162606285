@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-signInForm {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0;
  text-align: center;
  height: 100%;

  @include tablet {
    height: auto;
  }

  &__title {
    font-size: $font-size-22;
    font-weight: 500;
    margin: 0;
    padding-bottom: 48px;

    @include desktop {
      font-size: $font-size-35;
      padding-bottom: 38px;
    }
  }

  &__formError {
    text-align: center;
    color: $radical-red;
    font-size: $font-size-12;
    min-height: 12px;
    padding-bottom: 10px;
  }

  &__form {
    text-align: left;
    height: 100%;

    @include desktop {
      min-width: 288px;
      margin: auto;
    }
  }

  &__formControl {
    margin-bottom: 16px;
  }

  &__staySign {
    text-align: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__staySignField {
    display: flex;
    align-items: center;
  }

  &__staySignLabel {
    font-size: $font-size-12;
  }

  &__resetPassword {
    font-size: $font-size-12;
    text-decoration: underline;
    outline: none;
  }

  &__signUp {
    font-size: $font-size-16;
    text-align: center;
  }

  &__signUpButton {
    font-size: $font-size-16;
    text-decoration: underline;
    margin-left: 7px;
  }

  &__loginButton {
    margin: auto;
  }
  
  &__loginLoadingButton {
    margin: auto;
  }

  &__staySignFieldCheckBox {
    margin-bottom: 2px;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  &__loader {
    margin-right: 27px;
    line-height: $font-size-20;
    
    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 15px;
      height: 15px;
      margin-left: 5px;
      margin-top: 3px;
      border-radius: 50%;
      border-top: 2px solid#DDE0E4;
      border-right: 2px solid transparent;
      animation: spinner 0.6s linear infinite;
    }
  }

  &__errorButton {
    text-decoration: underline;
    font-size: $font-size-12;
  }
}
