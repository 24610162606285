@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-input {
  border: none;
  font-size: $font-size-15;
  font-weight: normal;
  line-height: $font-size-24;
  outline: none;
  padding: 4px 12px;
  width: 100%;
  height: 36px;
  border: none;

  @include desktop {
    font-size: $font-size-15;
    line-height: $font-size-21;
  }

  &::placeholder {
    color: $spun-pearl;
  }


  &.grey {
    border: 1px solid $spanish-grey;
    border-radius: 4px;
    margin-top: 5px;

    &:focus {
      border: 2px solid $majorelle-blue;
      outline: none;
    }

    &.error {
      border: 1px solid $fiery-rose;
    }
  }

  &.dark {
    border: 1px solid $faint-grey;
    border-radius: 4px;

    &:focus {
      border: 2px solid $majorelle-blue;
      outline: none;
    }

    &.error {
      border: 1px solid $fiery-rose;
    }
  }

  &.checkbox {
    display: flex;
    border: 1px solid $nero;
    padding: 0;
    margin-right: 5px;
    width: auto;
    width: 12px;
    height: 12px;
  }

  &__wrapper {
    position: relative;
    width: 100%;

    &--checkbox {
      width: auto;
      margin: auto;
    }

    &--radio {
      display: inline;
      width: auto;

      input {
        height: initial;
      }

      &:checked:after {
        background-color: $nero;
      }
    }
  }

  &__viewIconCta {
    color: $nero;
    position: absolute;
    // right: 10px;
    top: 48%;

    @include desktop {
      top: 52%;
    }  
  }

  &__labelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__errorMessage {
    font-size: $font-size-12;
    color: $fiery-rose;
    margin-left: auto;
  }

  &__uploadFormLabel {
    font-size: $font-size-15;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 5px;
    padding-top: 5px;
  }

  &__inputContainer {
    border-radius: 4px;
    width: 100%;
    display: inline;

    &.showPrefix {
      display: inline-flex;
    }
  }

  &__inputPrefix {
    border: solid 1px $gray59;
    border-radius: 2px 0px 0px 2px;
    border-right: 0px;
    margin: auto;
    background-color: $platinum;
    line-height: 34px;
    padding: 0 7px;
    font-family: Heebo;
    font-size: $font-size-18;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $nero;
  }
}
