@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-transfer {
  padding: 0 30px;

  &__sellerInfo {
      margin-top: 20px;
  }

  &__form {
      margin-top: 50px;
  }

  .tmx-input__wrapper {
      margin-top: 20px;
  }

  &__dropDownContainer {
    margin-top: 20px;  
  }

  &__dropDown {
    border: 1px solid $nero;
    color: $gray59;
    outline: none;
    padding: 4px 12px;
    width: 100%;
    height: 36px;
  }

  &__btn {
    display: initial;
    height: 29px;
    text-align: center;
    margin: auto;
    font-size: $font-size-14;
    margin-top: 50px;

    span {
      display: inline-block;
    }
  }

  &__errorMessage {
    font-size: $font-size-12;
    color: $fiery-rose;
    margin-left: auto;
  }
}
