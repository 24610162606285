@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-contentNotFound {
  background-color: $majorelle-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  @include desktop {
    height: 100vh;
  }

  &__image {
    width: 266px;
  }

  &__textWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;
    font-size: $font-size-16;

    @include desktop {
      flex-direction: row;
    }
  }

  &__link {
    color: $white;
    font-weight: 500;
    margin-left: 6px;
  }
}
