@font-face {
    font-family: 'Heebo';
  
    src: url('/fonts/heebo/Heebo-ExtraBold.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Bold.woff') format('woff'),
      url('/fonts/heebo/Heebo-Bold.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Bold.svg#Heebo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-Black.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Black.woff') format('woff'),
      url('/fonts/heebo/Heebo-Black.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Black.svg#Heebo-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-ExtraBold.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-ExtraBold.woff') format('woff'),
      url('/fonts/heebo/Heebo-ExtraBold.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-ExtraBold.svg#Heebo-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-SemiBold.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-SemiBold.woff') format('woff'),
      url('/fonts/heebo/Heebo-SemiBold.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-SemiBold.svg#Heebo-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-Regular.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Regular.woff') format('woff'),
      url('/fonts/heebo/Heebo-Regular.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Regular.svg#Heebo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-Light.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Light.woff') format('woff'),
      url('/fonts/heebo/Heebo-Light.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Light.svg#Heebo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-Medium.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Medium.woff') format('woff'),
      url('/fonts/heebo/Heebo-Medium.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Medium.svg#Heebo-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-Thin.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-Thin.woff') format('woff'),
      url('/fonts/heebo/Heebo-Thin.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-Thin.svg#Heebo-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Heebo';
    src: url('/fonts/heebo/Heebo-ExtraLight.woff2') format('woff2'),
      url('/fonts/heebo/Heebo-ExtraLight.woff') format('woff'),
      url('/fonts/heebo/Heebo-ExtraLight.ttf') format('truetype'),
      url('/fonts/heebo/Heebo-ExtraLight.svg#Heebo-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  