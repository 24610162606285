@import '../../../styles/variables/colors';
@import '../../../styles/mixins/mediaqueries';
@import '../../../styles/variables/sizes';

.tmx-textArea {
  font-size: 18px;
  padding: 4px 12px;
  width: 100%;
  height: 100px;
  line-height: 28px;
  overflow: scroll;
  resize: none;
  border: none;

  &::placeholder {
    color: $spun-pearl;
  }

  @include desktop {
    overflow: auto !important;
  }

  &.grey {
    border: 1px solid $gray59;
    border-radius: 2px;
  }

  &.pitchSummary {
    min-height: 72px;
  }

  &.error {
    border: 1px solid $red;
  }

  &.usageGuidelines {
    height: 170px !important;
  }

  &.dark {
    border: 1px solid $gray59;
    border-radius: 4px;
    font-size: $font-size-15;
    overflow: auto;

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    border: 1px solid $gray59;
  }

  &__textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 40px;
    line-height: 20px;
  }

  &__statusBar {
    padding: 4px 0;
    text-align: right;
    font-size: 12px;
    width: 100%;
    color: $granite-grey;
    border-radius: 0 0 4px 4px;

    &.error {
      color: $red;
    }
  }

  &__counterPara {
    width: auto;
    margin-left: auto;
    line-height: 16px;
    margin: 0;
    padding: 0;
  }

  &__uploadFormLabel {
    font-size: $font-size-15;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 700;
    color: $dark-grey;
  }

  &__labelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    color: $granite-grey;
  }

  &__errorMessage {
    font-size: 15px;
    color: $red;
    margin-left: auto;
    margin-right: 5px;
  }
}
