@import '../../../styles/variables/colors';
@import '../../../styles/mixins/mediaqueries';

.tmx-progressBar {
  background-color: $turquoise-alpha;
  height: 8px;
  border-radius: 4px;
  margin: 24px;
  position: relative;

  &--upload {
    height: 8px;
    border-radius: 4px;
    margin: 0;
    background-color: $white;
    position: relative;
    width: 100%;

    &__fill {
      border-radius: 4px;
      position: absolute;
      height: 8px;
      left: 0;
      background-color: $spanish-grey;
      transition: width 0.5s ease-in;

      &--green {
        border-radius: 4px;
        position: absolute;
        height: 8px;
        left: 0;
        background-color: $turquoise;
        transition: width 0.5s ease-in;
      }

      &--red {
        border-radius: 4px;
        position: absolute;
        height: 8px;
        left: 0;
        background-color: $brink-pink;
        transition: width 0.5s ease-in;
      }
    }
  }

  @include desktop {
    margin: 24px 0;
  }

  &__fill {
    display: flex;
    justify-content: flex-end;
    background-color: $turquoise;
    border-radius: 4px;
    position: absolute;
    height: 8px;
    left: 0;
  }

  &__fillDot {
    height: 16px;
    width: 16px;
    background-color: $gray59;
    border-radius: 50%;
    margin-top: -6px;

    &--blue {
      background-color: $majorelle-blue;
    }
  }
}
