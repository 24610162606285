@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-expertProfileDetails {
  padding: 86px 16px;
  background: $guyabano;
  font-family: 'IBM Plex Sans', sans-serif;

  @include tablet {
    padding: 96px 51.5px;
  }

  @include desktop {
    padding: 96px 183.5px;
  }

  &__companyName {
    font-size: $font-size-32;
    font-weight: normal;
    color: $granite-grey99;
    text-align: center;
  }

  &__overallContainer {
    background-color: $white;
    padding: 40px;
    border-radius: 20px;

    @include tablet {
      padding: 40px 80px 120px 80px;
    }
  }

  &__orgSettingsHeader {
    border-bottom: 1px solid $spun-pearl;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-size: $font-size-22;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $granite-grey99;
      width: 42%;
    }

    &.error {
      border-bottom: 1px solid $fiery-rose;
    }
  }

  &__organizationInfo,
  &__userSettings,
  &__premiumMembership,
  &__premiumTrial {
    margin-top: 56px;
  }

  &__users {
    max-height: 220px;
    overflow-y: hidden;
    overflow-x: hidden;

    @include tablet {
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $whisper;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $spanish-grey;
      border-radius: 10px;
    }
  }

  &__UserContainer {
    display: grid;
    grid-template-columns: none;
    border-bottom: 1px solid $crayola-crystal;
    padding: 10px 0;
    grid-gap: 6px;

    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 41px;

      &--newsRoom {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    &--heading {
      display: none;
      @include tablet {
        display: grid;
      }
    }

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 25px;
      margin-bottom: 5px;
    }
  }

  &__orgOwner {
    color: $ghost;
    margin-right: 7px;
  }

  &__UserContainerName {
    margin: auto 0;

    &--bold {
      font-weight: 500;
    }

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__UserContainerEmail {
    margin: auto 0;

    &.header {
      font-size: $font-size-12;
      font-weight: 500;
    }
  }

  &__UserContainerAction {
    margin: 0;
    text-align: left;

    @include tablet {
      text-align: right;
      margin: auto 10px;
    }
  }

  &__UserContainerInviteBtn {
    text-align: left;
    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      display: block;
    }
  }

  &__btn {
    display: initial;
    height: 29px;
    text-align: center;
    margin: auto;
    font-size: $font-size-14;
  }

  &__sortIcon {
    display: inline;
    outline: none;
    cursor: pointer;
  }

  &__saveBtn {
    margin: 56px auto 0;
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 4px 65px;
  }

  &__requestBtn {
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 4px 40px;
    // font-size: $font-size-12;

    @include desktop {
      padding: 4px 87px;
      // font-size: $font-size-16;
    }
  }

  &__invitBtn {
    border-radius: 22px;
    background-color: $ghost-white;
    padding: 4px 86px;
    color: $granite-grey99;
    margin: 20px 0px 0;
    font-weight: 500;
    // font-size: $font-size-12;

    @include desktop {
      padding: 4px 87px;
      // font-size: $font-size-16;
    }
  }

  &__invitBtnIcon {
    color: $spun-pearl;
  }

  &__backButton {
    transform: rotate(180deg) !important;
    color: $spun-pearl;
    padding-top: 0 !important;
  }

  &__formInlineWrapper {
    margin-top: 24px;

    @include tablet {
      display: flex;
    }
  }

  &__formInline {
    flex: 0 0 50%;
    padding-bottom: 25px;

    .tmx-dropDown.grey {
      color: $black;
    }

    &.space {
      padding-right: 30px;
    }

    &--loading {
      background-color: $light-grey;
      animation: fade 2s infinite ease-in-out;
      height: 57px;
    }
  }

  &__formTitle {
    font-size: $font-size-18;
    font-weight: 600;
    line-height: normal;
    color: $granite-grey99;
  }

  &__formTitleOrg {
    font-size: $font-size-18;
    font-weight: 600;
    line-height: normal;
    color: $granite-grey99;
    padding-top: 10px;
  }

  &__formDetails {
    font-size: $font-size-15;
    font-weight: normal;
    line-height: normal;
    color: $granite-grey99;
    padding: 10px 0;
  }

  &__sliderWrapper {
    display: flex;
    align-items: center;
  }

  &__monthly {
    margin-right: 8.5px;
  }

  &__editButon {
    font-size: $font-size-16;
  }

  &__backButtonContainer {
    // width: 17%;
    background: $white;
    text-align: center;
    position: absolute;
    margin-top: 8px;
    // padding: 150px 16px 0;
    z-index: 12;

    .tmx-button {
      font-size: $font-size-16;
    }

    @include tablet {
      // padding: 110px 36px 0;
    }
  }

  &__backContent {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: 500;
    margin-left: -28px;
    margin-top: -5px;

    .icon {
      transform: rotate(90deg);
      padding-right: 0;
      padding-top: 8px;
    }

    @include desktop {
      margin-left: 0px;
    }
  }

  &__success {
    color: $dark-pastel-green;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__error {
    color: $radical-red;
    text-align: center;
    margin-top: 30px;
    font-style: italic;
  }

  &__subFormDetails {
    margin-top: 16px;
  }

  &__subFormDetailsPayment {
    margin-top: 0;
    font-weight: normal;
    line-height: normal;
    color: $granite-grey99;
    font-size: $font-size-15;
  }

  &__fade {
    opacity: 0.3;
  }

  &__paymentEmail {
    margin-top: 12px;
  }

  &__customBtn {
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 4px 37px;
  }

  &__OCScontainer {
    display: flex;
    align-items: center;

    p {
      margin: 0 11px;
    }

    .tmx-input__wrapper--checkbox {
      margin: 0;
    }
  }

  &__uploadFormLabel {
    font-size: $font-size-16;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-grey;
    height: 25px;
    display: block;
  }

  &__feesLabel {
    margin: 20px 0;
  }

  &__paymentBtn {
    border-radius: 22px;
    background-color: $granite-grey99;
    padding: 4px 50px;
    margin-top: 20px;
    font-size: $font-size-12;
    color: white;

    @include desktop {
      padding: 4px 102px;
      font-size: $font-size-16;
    }
  }

  &__newsroomLabel {
    padding-top: 20px;
  }

  &__inputForm {
    min-height: 70px;
    min-width: 320px;
    max-height: 75px;
    margin-bottom: 30px;
  }

  &__inputGrid {
    flex: 0 0 50%;
    width: 100%;
  }

  &__urlLable {
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6c6c84;
    height: 25px;
    display: block;
    margin-top: 25px;
  }
}
