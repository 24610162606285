@import './styles/utils/normalize';
@import './styles/fonts/heebo';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  font-size: 16px;
  overflow-x: hidden;

}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

.disableScroll {
  overflow: hidden;
}

iframe {
  //setting the z-index of this to -1 because there is 
  // an iframe that keeps popping up when you click on 
  // anything on the dashboard without getting redirected 
  // to another page. This iframe is adding some kind of 
  // overlay to the page and preventing us from doing any other 
  // action on the page. We should do more investigation 
  // on where this iframe is coming from later and if we should 
  // get rid of it
  z-index: -1 !important; 
}