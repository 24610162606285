@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-uploadForm {
  min-height: 800px;

  &__uploadLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }

    button {
      background: none;
      border: none;
      color: $granite-grey;
    }
  }

  &__userDropDown {
    @include desktop {
      width: 400px;
    }
  }

  &__iconAttach {
    font-size: 2rem;
  }

  &__URL {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    color: $granite-grey;
  }

  &__postURL {
    display: flex;
    justify-content: space-between;
    div:first-child {
      width: 100%;
    }

    &--disable {
      pointer-events: 'none';
    }
  }

  &__addNewsroomFormContainer {
    margin: 10px;
  }

  &__locationInputContainer{
    margin-top: 5px;
  }

  &__addNewsroomFormButton {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: $lucky-point;
  }

  &__postURL--delete {
    cursor: pointer;
  }

  &__checkboxContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @include extra-small-mobile {
      flex-direction: column;

      .tmx-uploadForm__orgDropDown {
        margin-left: 25px;
        color: $granite-grey;
      }
    }
  }

  &__checkboxLabel {
    width: 200px;
  }

  &__label {
    margin-bottom: 10px;
  }

  &__URLField {
    margin-bottom: 15px;
  }

  &__graphicContent {
    display: flex;
    align-items: center;

    @include extra-small-mobile {
      margin-bottom: 10px;
    }

    &__boost {
      padding: 25px 0;
    }

    &__orgDropDown {
      width: 40%;
      padding: 8px 12px !important;
    }

    &__senderOrg {
      width: 100%;

      @include extra-small-mobile {
        margin-top: 20px;
      }
    }

    .tmx-dropDown {
      width: 100%;
    }
  }

  &__labelWrapper {
    font-size: $font-size-15;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 5px;
    padding-top: 5px;
  }

  &__dateSubText {
    font-size: $font-size-12;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $light-grey;
    margin-top: 6px;
  }

  &__creatorDescription {
    width: 100%;
  }

  &__pitchDateText {
    display: inline-block;
    font-size: 15px;
  }

  &__pitchDateTextButton {
    font-size: $font-size-16;
  }

  &__creatorDescriptionTextArea {
    min-height: 72px;
    max-height: 250px;
    overflow-y: auto;
  }

  &__pitchSummary {
    min-height: 72px;
    width: 100%;

    .ql-toolbar.ql-snow {
      border: 1px solid $faint-grey;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-container {
      min-height: 10em;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: $font-size-15;
      border: 1px solid $faint-grey;

      .ql-editor.ql-blank::before {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        color: $spun-pearl;
      }
    }
  }

  &__credits {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .tmx-uploadForm__graphicContent {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include extra-small-mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .credits-input {
        width: 70%;
        border: 1px solid $faint-grey;
        border-radius: 4px;

        @include extra-small-mobile {
          margin-bottom: 10px;
          width: 100%;
        }

        .credits-input-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 80%;
          height: 38px;
          padding-left: 10px;

          div:nth-child(1) {
            width: 100%;
          }
          textarea {
            border: none !important;
          }

          .tmx-textArea {
            padding: unset;
            height: unset;
            width: 100%;
          }
        }
      }

      .tmx-textArea__labelWrapper {
        display: none;
      }

      .watermark {
        display: flex;
      }
    }
  }

  .field {
    margin-bottom: 25px;

    @include desktop {
      width: 400px;
    }
  }

  .dateInput {
    margin-top: 20px;

    .tmx-input__wrapper {
      border: 1px solid $lucky-point;
      margin-top: 10px;
      padding: 2px 6px;
    }
  }

  .upload-to-tmx {
    display: flex;
    align-items: center;
    margin-top: 50px;

    .tmx-button {
      display: flex;
      align-items: center;
      background: $deep-grey;
      border-radius: 30px;
      color: white;
      padding: 5px 28px;
      font-size: 20px;

      &:disabled {
        display: flex;
        align-items: center;
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}
