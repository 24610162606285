@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/mixins/mediaqueries';

.tmx-overlay {
  &__wrapper {
    top: 0;
    right: 0;
    position: fixed;
    z-index: 20;
    background-color: $white;
    height: 100%;
    transition: transform 500ms ease;
    width: 500px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    padding: 0 50px;

    @include small-mobile {
      padding: 0px 8px 0 15px;
    }

    @include tablet {
      width: 500px;
      transform: translateX(106%);
    }

    @include desktop {
      width: 500px;
    }

    @include small-screen {
      width: 100%;
      transform: translateY(150%);
    }

    &--active {
      z-index: 20;
      overflow-x: hidden;
      transform: translateX(0%);
      padding: 0 50px;

      @include small-screen {
        transform: translateY(0%);
      }
    }
  }

  &__container {
    transition: transform 500ms ease;

    &--right {
      transform: translateY(-500px);

      @include desktop {
        transform: translateX(-500px);
      }
    }

    &--remove {
      display: none;
    }
  }

  &__title {
    padding: 60px 0;
    font-size: $font-size-36;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  &__closeIcon {
    color: $dark-grey;
    text-align: right;
    position: relative;
    padding-top: 50px;
    cursor: pointer;
  }

  &__backButton {
    font-weight: 500;
    line-height: normal;
    font-size: $font-size-14;
    padding-left: 22px;
    margin-bottom: 30px;

    .icon {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      padding-right: 0;
      padding-top: 8px;
    }
  }
}
