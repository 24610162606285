@import '../../../styles/fonts/tmx-icons';
@import '../../../styles/variables/sizes';

.icon {
  font-family: 'tmx-icons';

  &:focus {
    outline: none;
  }

  &.micro {
    font-size: $font-size-10;
  }

  &.small {
    font-size: $font-size-14;
  }

  &.medium {
    font-size: $font-size-16;
  }

  &.large {
    font-size: $font-size-24;
  }

  &.huge {
    font-size: $font-size-28;
  }

  &.ginormous {
    font-size: $font-size-36;
  }

  &.evenBigger {
    font-size: $font-size-50;
  }
}
