@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';
@import '../../styles/variables/animation';
@import '../../styles/mixins/mediaqueries';

.tmx-contentSellers {
  padding: 155px 16px 0;
  font-family: 'IBM Plex Sans', sans-serif;

  @include tablet {
    width: 415px;
    margin: auto;
    padding: 88px 0;
  }

  &__title {
    font-size: $font-size-36;
    margin-bottom: $font-size-36;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: normal;
    text-align: center;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: $light-grey;

    @include tablet {
      font-size: $font-size-32;
    }
  }

  &__count {
    color: $gray71;
    font-size: $font-size-15;
    text-align: center;
  }

  &__result {
    margin-top: 24px;
  }

  &__content {
    margin-bottom: 16px;
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    display: flex;
    padding: 10px 30px 14px;

    &.search {
      height: initial;
      padding: 11px 30px;
    }
  }

  &__contentTitle {
    color: $light-grey;
    font-size: $font-size-24;
    font-weight: normal;
    text-align: left;
    display: block;

    p {
      margin: 0;
      font-size: $font-size-18;
      line-height: 1.56;
    }

    &.search {
      word-break: break-all;
    }
  }

  &__contentSellerName {
    font-weight: 600;
  }

  &__userDetails {
    margin-left: 16px;
  }

  &__userProperty {
    font-size: $font-size-18;
    color: $dark-grey;
    line-height: normal;
  }

  &__searchWrapper {
    margin-bottom: 30px;
  }

  &__mock {
    background-color: $light-grey;
    height: 22px;
    width: 80%;
    margin: auto;
  }

  &__mockContent {
    background-color: $light-grey;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
    height: 50px;
  }

  &__btn {
    margin: 30px auto 54px;
    height: 29px;
    text-align: center;
    font-size: $font-size-14;
    border-radius: 22px;
    background-color: $ghost-white;
    color: $light-grey;
    font-size: $font-size-18;
    letter-spacing: 0.5px;
    padding: 20px 55px;
    outline: none;

    &:focus {
      background-color: $ghost-white;
    }
  }
  
}
