@import '../../../styles/variables/colors';
@import '../../../styles/variables/sizes';
@import '../../../styles/variables/animation';
@import '../../../styles/mixins/mediaqueries';

.tmx-slideToggle {
  display: flex;
  height: 22px;
  line-height: 22px;
  outline: none;
  font-family: 'IBM Plex Sans', sans-serif;

  &__label {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: inherit;

    @include desktop {
      cursor: pointer;
    }
  }

  &__content {
    margin-left: 8.5px;
    color: $granite-grey99 !important;
  }

  &__bar {
    position: relative;
    width: 46px;
    height: 22px;
    background-color: $gray-x;
    transition: all 80ms linear;
    transition-property: background-color;
    transition-delay: 50ms;
    border-radius: 10px;

    &--selected {
      background-color: $dark-pastel-green;
    }

    &--disabled {
      cursor: initial;
    }

    &--blue {
      background-color: $white;
      border: 1px solid $majorelle-blue;
    }

    &--grey {
      background-color: $white;
      border: 1px solid $crayola-crystal;
    }

    &--green {
      background-color: $dark-pastel-green;
      border: 1px solid $dark-pastel-green;
    }
  }

  &__input {
    bottom: 0;
    left: 10px;
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    display: none;
  }

  &__thumbWrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    top: calc(50% - 7px);
    left: 4px;
  }

  &__thumbText {
    font-size: 14px;
    position: absolute;
    bottom: 1px;
    transform: translate3d(22px, 0, 0);
    transition: all 80ms linear;
    transition-property: transform;
    color: $white;
    right: 6px;

    &--selected {
      transform: translate3d(0, 0, 0);
      right: 0;
      color: $white;
    }

    &--blue {
      color: $majorelle-blue;
    }

    &--grey {
      color: $crayola-crystal;
    }

    &--green {
      color: $sea-green;
    }
  }

  &__thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $white;
    transform: translate3d(0, 0, 0);
    transition: all 80ms linear;
    transition-property: transform;

    &--selected {
      transform: translate3d(22px, 0, 0);
      background-color: $sea-green-new;
    }

    &--disabled {
      background-color: $white;
    }

    &--blue {
      background-color: $majorelle-blue;
    }

    &--grey {
      background-color: $crayola-crystal;
    }

    &--green {
      background-color: $sea-green;
    }
  }
}
