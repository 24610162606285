$baby-pink: #ffc7c7;
$black-alpha: rgba(0, 0, 0, 0.58);
$black-card: rgba(34, 34, 34, 0.16);
$black-dormunt: rgba(0, 0, 0, 0.2);
$black-russian: rgba(0, 0, 0, 0.06);
$black: #000000;
$brink-pink: #fe4f80;
$christalle: #38285b;
$crayola-crystal: #d5d5d5;
$cultured-dark: #f4f4f4;
$cultured-light: #f0f0f0;
$cultured: #f1f1f1;
$dark-goldenrod: #b28337;
$dark-pastel-green: #27c424;
$deep-champagne: #ffd084;
$duke-blue: #110992;
$eerie-black: rgba(34, 34, 34, 0.9);
$fiery-rose: #ff506d;
$granite-grey: #686868;
$gray-x: #bdbdbd;
$gray59: #969696;
$gray70: #b3b3b3;
$gray72: #b8b8b8;
$gray94: #f0f0f0;
$green-ncs: #31a068;
$hawkes-blue: #dde0e4;
$jaguar: #1f1f37;
$jet: #3c3c3c;
$lavender-blue: #cbbefe;
$lavender-web-light: #e9e8f9;
$lavender-web: #eee8fe;
$lavender: #d2d2fc;
$light-gray: #d1d1d1;
$light-silver: #d8d8d8;
$lightgray: #c0c0c0;
$majorelle-blue-alpha: rgba(89, 79, 254, 0.1);
$majorelle-blue: #594ffe;
$malachite: #39d95f;
$maximum-blue-purple: #a39dff;
$maximum-blue: #5ebbd5;
$maximum-yellow-red: #ffbd50;
$medium-purple: #8b84fe;
$nero: #222222;
$persian-blue: #3e37b1;
$platinum: #e6e6e6;
$radical-red: #ff3d5e;
$rose-red: #be3b5f;
$shadow-blue: rgba(89, 79, 254, 0.32);
$spanish-grey-dark: #979797;
$spanish-grey: #929292;
$trolley-grey: #808080;
$turquoise-alpha: rgba(80, 226, 194, 0.15);
$turquoise: #50e2c2;
$whisper: #ebebeb;
$white: #ffffff;
$zomp: #379d87;
$gray71: #b5b5b5;
$hawkes-blue: #dde0e4;
$lightgray: #c0c0c0;
$gray59: #969696;
$light-silver: #d8d8d8;
$light-orange: #ffbd50;
$red: #ff0000;
$deep-grey: #70708c;
$deep-persian-blue: #5b44f5;
$dark-violet: #5b49fe;
$light-green: #50e2c2;
$light-blue: #a9eaff;
$light-lemon: #bde5df;
$lighter-lemon: #ebf7f5;
$deep-cream: #f2e5d4;
$deep-blue: #333363;
$light-purple: #d7d5ff;
$lightest-purple: #f7f7fa;
$lighter-purple: #f2f2ff;
$lucky-point: #32324d;
$granite-grey99: #5d5d74;
$sea-green: #030303;
$light-grey: #d5d5d4;
$dark-blue: #2d2d4f;
$dark-white: #fcfcfc;
$darker-grey: #263238;
$light-gray: #fafafa;
$gray64: #646464;
$deep-blue: #34345c;
$dark-grey: #6c6c84;
$lighter-blue: #e5f8ff;
$hawkes-blue: #dde0e4;
$light-grey: #919199;
$light-slate-grey: #7a7a99;
$faint-grey: #dfdfe6;
$majorelle-blue: #594ffe;
$solitude: #e9e9f0;
$shades-of-whisper: #eaeaea;
$steel-gray-zero-opacity: rgba(31, 31, 51, 0) 0%;
$steel-gray-with-opacity: rgba(31, 31, 51, 0.65) 80%;
$ghost-white-opacity: rgba(0, 0, 0, 0.15);
$athens-gray: #f0f0f5;
$lighter-grey: #ceced9;
$spun-pearl: #aeaebd;
$light-grey: #5d5d74;
$light-grey-blue: #dfdfe6;
$ghost-white: #f8f8fa;
$guyabano: #f8f8f8;
$lucky-point: #32324d;
$granite-grey99: #5d5d74;
$guyabano: #f8f8f8;
$sea-green-new: #a5ffbe;
$ghost: #bfbfcb;
$another-gray: #e2e2e9;
$H240: #dfdfe6;
$waterloo: #70708c;